
import React, { useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import HttpsIcon from '@mui/icons-material/Https';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import urlConfig, { url } from '../../urlConfig';

function NewPassword() {
  const [email, setEmail] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [error, setError] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isPasswordStage, setIsPasswordStage] = useState(false);
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();

  const handleContinue = async () => {
    if (!email) {
      setError('Please enter a valid email address.');
      toast.error('Please enter a valid email address.');
      return;
    }
    try {
      const response = await fetch(`${url}/send-forgot-otp`, { // Updated API endpoint
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (data.status) {
        setIsOtpSent(true);
        toast.success('OTP sent  successfully!');
        setError('');
      } else {
        setError(data.message);
        toast.error(data.message);
      }
    } catch (err) {
      setError('Failed to send OTP. Please try again.');
      toast.error('Failed to send OTP. Please try again.');
    }
  };
  
  const handleOtpSubmit = async () => {
    if (otp.length !== 4) {
      setError('Please enter a valid 4-digit OTP.');
      toast.error('Please enter a valid 4-digit OTP.');
      return;
    }
    try {
      const response = await fetch(`${url}/verify-forgot-otp`, { 
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, OTP: otp }),
      });
      const data = await response.json();
      if (data.status) {
        setIsPasswordStage(true);
        toast.success('OTP verified successfully!');
        setError('');
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError('Failed to verify OTP. Please try again.');
      toast.error('Failed to verify OTP. Please try again.');
    }
  };
  
  const handlePasswordSubmit = async () => {
    if (!password || password !== rePassword) {
      setError('Passwords do not match.');
      toast.error('Passwords do not match.');
      return;
    }
    try {
      const response = await fetch(`${url}/forgotPassword-update`, { // Updated API endpoint
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, newPassword: password }),
      });
      const data = await response.json();
      if (data.status) {
        toast.success('Password updated successfully!');
        setTimeout(() => {
          navigate("/");
        }, 2000); 
      } else {
        setError(data.message);
        toast.error(data.message);
      }
    } catch (err) {
      setError('Failed to update password. Please try again.');
      toast.error('Failed to update password. Please try again.');
    }
  };
  

  return (
    <div className="lg:flex max-sm:flex-none">
        <ToastContainer /> 
      <img
        src="./login.png"
        alt="Login Illustration"
        className="lg:w-[40%] lg:h-[100vh] max-sm:w-[100%] max-sm:h-[35vh] sm:w-[100%] sm:h-[40vh] sm:p-3 lg:p-7 max-sm:p-3"
      />
      <div className="lg:p-10 max-sm:p-4 sm:p-10 items-center lg:pt-28 lg:ml-20">
        <img src="./Watch.png" alt="Logo" className="w-20 h-20 max-sm:hidden" />
        <h1 className="text-[32px] font-bold lg:mt-7 max-sm:-mt-5">
          Forgot Password!
        </h1>
        <p className="pb-5 ">
          {isPasswordStage
            ? 'Please create a new password'
            : isOtpSent
            ? 'Please enter your OTP'
            : 'Please provide your e-mail for reset password.'}
        </p>

        {/* Conditional Input for Email, OTP, or Password */}
        {!isOtpSent && !isPasswordStage ? (
          <>
            <label className="font-medium max-sm:text-[20px]">Email</label>
            <div className="flex border-2 border-[#D0D5DD] py-2 px-4 lg:w-[370px] sm:w-[60%] rounded-xl shadow-sm lg:mt-2 mb-5">
              <EmailIcon className="text-[#89C8D1] max-sm:mt-2" />
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-gray-400 focus:outline-none max-sm:text-[25px] w-full ml-4"
                style={{ fontFamily: "Montserrat" }}
              />
            </div>
          </>
        ) : isOtpSent && !isPasswordStage ? (
          <>
            <div className="flex space-x-2 mb-5">
              {[...Array(4)].map((_, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={otp[index] || ''}
                  onChange={(e) => {
                    const newOtp = otp.split('');
                    newOtp[index] = e.target.value;
                    setOtp(newOtp.join(''));
                  }}
                  onKeyUp={(e) => {
                    if (e.key !== 'Backspace' && otp[index]?.length === 1 && index < 5) {
                      document.getElementById(`otp-input-${index + 1}`).focus();
                    }
                    if (e.key === 'Backspace' && otp[index]?.length === 0 && index > 0) {
                      document.getElementById(`otp-input-${index - 1}`).focus();
                    }
                  }}
                  id={`otp-input-${index}`}
                  className="border-2 border-[#D0D5DD] w-10 h-12 text-center text-[#344054] focus:outline-none rounded-xl"
                />
              ))}
            </div>
          </>
        ) : isPasswordStage ? (
          <>
            <div className="flex border-2 border-[#D0D5DD] py-2 px-4 lg:w-[370px] rounded-xl shadow-sm lg:mt-2 mb-5">
              <HttpsIcon className="text-[#89C8D1] max-sm:mt-2" />
              <input
                type="password"
                required
                placeholder='Enter Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="text-[#344054] focus:outline-none w-full ml-4"
                style={{ fontFamily: "Montserrat" }}
              />
            </div>

            <div className="flex border-2 border-[#D0D5DD] py-2 px-4 lg:w-[370px] rounded-xl shadow-sm lg:mt-2 mb-5">
              <HttpsIcon className="text-[#89C8D1] max-sm:mt-2" />
              <input
                type="password"
                required
                placeholder='Re-Enter Password'
                value={rePassword}
                onChange={(e) => setRePassword(e.target.value)}
                className="text-[#344054] focus:outline-none w-full ml-4"
                style={{ fontFamily: "Montserrat" }}
              />
            </div>
          </>
        ) : null}

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <button
          onClick={
            isPasswordStage
              ? handlePasswordSubmit
              : isOtpSent
              ? handleOtpSubmit
              : handleContinue
          }
          className="text-[18px] max-sm:text-[16px] font-medium text-white bg-[#89C8D1] text-center lg:px-40 sm:px-40 max-sm:px-36 lg:py-2 sm:py-2 max-sm:py-3 max-sm:ml-1 rounded-xl hover:border border-gray-400"
        >
         {isPasswordStage ? 'Update Password' : isOtpSent ? 'Verify OTP' : 'Send OTP'}
        </button>
      </div>
    </div>
  );
}

export default NewPassword;
