import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideMenu from "../../Components/Sidebar/sidebar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { url } from "../../urlConfig";
import Navbar from "../../Components/Navbar/navbar";



function AllRewards() {
    const [showModal, setShowModal] = useState(false);
    const [editingReward, setEditingReward] = useState(null);
    const [title, setTitle] = useState("");
    const [costCoin, setCostCoin] = useState("");
    const [image, setImageFile] = useState(null);
    const [rewards, setRewards] = useState([]);
    const adminId = localStorage.getItem('theAdminId');
    const profileImage = localStorage.getItem("profile");
    const contactPerson = localStorage.getItem("contactPerson") || "Admin";
    const email = localStorage.getItem("email") || "default@example.com";
    const [secretCode, setSecretCode] = useState("");
    const [rewardId, setRewardId] = useState("");
    const [claimed,setClaimed]=useState([])
    const parsedProfileImage = profileImage ? JSON.parse(profileImage) : null;
    const navigate=useNavigate()


    const handleProfileClick = () => {
        console.log("Redirecting to profile page");
        navigate('/profile');
    };


    const openModal = (reward = null) => {
        setEditingReward(reward);
        setRewardId(reward ? reward._id : "");
        setTitle(reward ? reward.title : "");
        setCostCoin(reward ? reward.costCoin : "");
        setImageFile(reward ? reward.image : "");
        // setImagePreview(reward?.image || "/reward.png");
        setShowModal(true);
    };

    useEffect(() => {
        fetch(`${url}/rewards/${adminId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${localStorage.getItem('authToken')}`
            }
        })
            .then((res) => res.json())
            .then(response => {
                console.log("Fetch Rewards Response:", response); 
                if (response.status === true) {
                    setRewards(response.data);
                } else {
                    console.error("Failed to fetch rewards:", response);
                }
            })
            .catch(error => console.error("Error fetching rewards:", error));
    }, [adminId]);

    useEffect(() => {
        fetch(`${url}/get-claimed-rewards/admin/${adminId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${localStorage.getItem('authToken')}`
            }
        })
            .then((res) => res.json())
            .then(response => {
                console.log("Fetch Rewards Response:", response); 
                if (response.status === true) {
                    setClaimed(response.data);
                } else {
                    console.error("Failed to fetch rewards:", response);
                }
            })
            .catch(error => console.error("Error fetching rewards:", error));
    }, [adminId]);

    const handleCreateOrUpdateReward = async () => {
        if (!title || costCoin <= 0) {
            toast.error("Please fill out all fields and coin count must be greater than zero");
            return;
        }
    
        const rewardData = {
            adminId,
            secretCode,
            title,
            costCoin,
            image: image ,
        };
    
        try {
            let endpoint;
            let method;
    
            if (editingReward) {
              
                endpoint = `${url}/update-reward/${rewardId}`;
                method = 'PUT';
                rewardData.rewardId = rewardId; 
            } else {
               
                endpoint = `${url}/add-reward`;
                method = 'POST';
            }
    
            const response = await fetch(endpoint, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(rewardData),
            });
    
            const result = await response.json();
            console.log("Create/Update Response:", result); 
    
            if (result.status === true) {
                if (editingReward) {
                    // Update the rewards list
                    setRewards(rewards.map(r => (r._id === rewardId ? result.data : r)));
                    toast.success("Reward updated successfully!");
                } else {
                    // Add the new reward to the list
                    setRewards([result.data, ...rewards]);
                    toast.success("Reward created successfully!");
                }
                setShowModal(false);
            } else {
                console.error("Failed to create/update reward:", result);
                toast.error(result.message || "Failed to create/update reward");
            }
        } catch (error) {
            console.error("Error creating/updating reward:", error);
            toast.error(error.message || "An error occurred!");
        }
    };

    const handleFileChange = (e) => {
        const formData = new FormData();
    
            formData.append('newImage', e.target.files[0])
            console.log(e.target.file)
            // console.log(fileList)
            fetch(`${url}/upload-admin-profile-image/rewardImage`, {
                method: "POST",
                //   headers :{"Content-Type":"application/json" },
                body: formData
            }).then((res) => { return res.json() })
                .then(response => {
                    setImageFile(response.imageUrl)
                    // newEmployee.profileImage=response.imageUrl
                     console.log(response.imageUrl)
                })
    
      };
    

      const handleDeleteReward = async (id) => {
        console.log("Attempting to delete Reward with ID:", id); // Log the reward ID
    
        // Ask for user confirmation
        const isConfirmed = window.confirm("Are you sure you want to delete this reward?");
        if (!isConfirmed) {
            console.log("Reward deletion cancelled by user."); // Log cancellation
            return; // Exit if the user cancels
        }
    
        console.log("Deleting Reward with ID:", id); // Log the confirmed deletion
        try {
            const response = await fetch(`${url}/delete-reward/${id}`, {
                method: 'DELETE',
                headers: {
                    "Authorization": `bearer ${localStorage.getItem('authToken')}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    adminId,
                    rewardId: id,
                })
            });
    
            const result = await response.json();
            console.log("Delete Response:", result); // Log the delete response
    
            if (result.status === true) {
                setRewards(rewards.filter((reward) => reward._id !== id));
                toast.success("Reward deleted successfully!");
            } else {
                console.error("Failed to delete reward:", result);
                toast.error(result.message || "Failed to delete reward");
            }
        } catch (error) {
            console.error("Error deleting reward:", error);
            toast.error(error.message || "An error occurred!");
        }
    };
    

    return (
        <div className="flex w-full h-screen">
            {/* <SideMenu /> */}
            <div className=" max-sm:w-[100%] sm:w-[100%] overflow-scroll scrollbar-hide px-3">
                <ToastContainer />
                <div className="flex justify-between sticky top-0 max-sm:top-10 bg-white  lg:p-5 mt-5 max-sm:p-0 sm:p-0 sm:pt-10 max-sm:mt-10">
                    <div>
                        <h1 className="text-[22px] font-semibold">Rewards</h1>
                        <p>This Is Sub Description</p>
                    </div>
                    <div onClick={handleProfileClick} className="flex space-x-2 mr-3 max-sm:hidden cursor-pointer">
                    <img
                            src={parsedProfileImage ||  `${process.env.PUBLIC_URL}/4Shifter-icon.png`}
                            className="h-[40px] w-[40px] rounded-full"
                            alt={"admin profile"}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =  `${process.env.PUBLIC_URL}/4Shifter-icon.png`;
                            }}
                        />
                        <div className="text-[14px]">
                            <h1 className="text-blue-700 font-semibold">{contactPerson}</h1>
                            <p>{email}</p>
                        </div>
                    </div>
                </div>

           

                <div className="mt-10 lg:flex md:flex  lg:p-5 sm:p-0 max-sm:p-0">
                    <div className="lg:w-[50%] space-y-5">
                        <div className="flex justify-between sticky top-24 bg-white">
                            <h1 className="text-[22px] max-sm:text-[18px] font-semibold">Existing Rewards List</h1>
                            <button
                                style={{ fontFamily: 'Inder' }}
                                className="bg-blue-700 text-white lg:px-6 max-sm:px-3 max-sm:py-1 sm:px-3 lg:py-2 rounded-xl"
                                onClick={() => openModal()}
                            >
                                + Create Reward
                            </button>
                        </div>
                        <div className="overflow-y-scroll max-sm:scrollbar-hide h-full">
                            {rewards.map((reward) => (
                                <div key={reward._id}>
                                    <div className="pt-5 flex space-x-4 cursor-pointer hover:text-blue-700" >
                                        <img src={reward.image || `${process.env.PUBLIC_URL}/4Shifter-icon.png`} className="h-14 w-20 rounded-xl" />
                                        <div className="font-medium max-sm:text-[13px]">
                                            <p>{reward.title} ({reward.costCoin} coins)</p>
                                            <div className="flex space-x-4 ">
                                                <button className="hover:text-green-700" onClick={() => openModal(reward)}>
                                                    <BorderColorOutlinedIcon />
                                                </button>
                                                <button className="hover:text-red-700" onClick={() => handleDeleteReward(reward._id)}>
                                                    <DeleteOutlinedIcon />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-t border-dashed border-gray-400 mt-5 w-[95%]"></div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <div className="h-auto border lg:border-gray-300 lg:ml-4 max-sm:mt-5 sm:mt-10 max-sm:border-black sm:border-black"></div> */}

                    <div className="lg:ml-10 max-sm:mt-10 space-y-7 overflow-y-auto h-[490px]">
                        <h1 className="text-[22px] max-sm:text-[18px] font-semibold">Rewards Claimed By Employees</h1>
                     {claimed.map(i=>(
                        <>
                        <div className="flex font-medium  hover:text-blue-900">
                            <img src={i?.reward?.image || "../4Shifter-icon.png"} className="h-12 w-12 rounded-full" />
                            <img src={i?.employee?.profileImage || "../4Shifter-icon.png"}className="h-12 w-12 rounded-full left-1/2 -ml-7" />
                            <p className="lg:mr-14 ml-5 max-sm:text-[14px]">{i?.employee?.name} claimed {i?.reward?.title} for {i?.reward?.costCoin}</p>
                            {/* <NavigateNextIcon /> */}
                        </div>

                        <div className="border-t border-dashed border-gray-400 mt-3 w-[100%]"></div>
                        </>
                     ))}
                        

                        {/* <div className="flex font-medium cursor-pointer hover:text-blue-900">
                            <img src="https://cdn.mos.cms.futurecdn.net/FkGweMeB7hdPgaSFQdgsfj.jpg" className="h-12 w-12 rounded-full" />
                            <img src="https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg" className="h-12 w-12 rounded-full left-1/2 -ml-7" />
                            <p className="lg:mr-14 ml-5 max-sm:text-[14px]">test claimed smart watch for 1000 coins</p>
                            <NavigateNextIcon />
                        </div>
                        
                        <div className="border-t border-dashed border-gray-400 mt-3 w-[100%]"></div> */}
                        
                    </div>
                
            </div>

            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center max-sm:p-2">
                    <div className="bg-white p-8 rounded-xl w-full max-w-md">
                        <h2 className="text-2xl font-semibold mb-4">
                            {editingReward ? "Edit Reward" : "Create New Reward"}
                        </h2>

                        <div className="mb-4">
                            <label className="block text-gray-700">Reward Title</label>
                            <input
                                type="text"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700">Coin Cost</label>
                            <input
                                type="number"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={costCoin}
                                onChange={(e) => setCostCoin(e.target.value)}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700">Image</label>
                           
                            <input
                                type="file"
                                className="w-full"
                                onChange={handleFileChange}
                            />
                        </div>

                        <div className="flex justify-end">
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-blue-700 text-white px-4 py-2 rounded-lg"
                                onClick={handleCreateOrUpdateReward}
                            >
                                {editingReward ? "Update Reward" : "Create Reward"}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
        </div >
    );
}

export default AllRewards;
