import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../Components/Navbar/navbar";
import SideMenu from "../../Components/Sidebar/sidebar";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { url } from "../../urlConfig";
import { toast, ToastContainer } from 'react-toastify';
import { Link, useLocation } from "react-router-dom";
import ReasonModal from "./modal";
import { MdClose } from 'react-icons/md';

function Leaves() {
   const location = useLocation();
   const queryParams = new URLSearchParams(location.search);
   const employeeeid2 = queryParams.get('employeeId');

   const [employee, setEmployee] = useState("");
   const [employees, setEmployees] = useState([]);
   const [selectedSection, setSelectedSection] = useState("new");
   const [dropdownOpen, setDropdownOpen] = useState(false);
   const [leaveRequests, setLeaveRequests] = useState([]);
   const [loading, setLoading] = useState();
   const [groups, setGroups] = useState([]);
   const [acceptedLeaveRequests, setAcceptedLeaveRequests] = useState([]);
   const [rejectedLeaveRequests, setRejectedLeaveRequests] = useState([]);
   const [selectedDate, setSelectedDate] = useState("");
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [reason, setReason] = useState("");
   const [body, setBody] = useState("");
   const [groupId, setGroupId] = useState("")
   const [employeeId, setEmployeeId] = useState(queryParams.get('employeeId'))

   const inputRef = useRef(null);

   const formatDate = (date) => {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
   };

   const handleLabelClick = () => {
      // Trigger focus on the input field when label is clicked
      inputRef.current?.focus();
   };

   console.log(employeeId)
   //    useEffect(()=>{
   //       setEmployeeId(queryParams.get('employeeId'))
   //    },[queryParams.get('employeeId')])


   const handleViewClick = (reason, body) => {
      setReason(reason);
      setBody(body);
      setIsModalOpen(true);
   };

   const closeModal = () => {
      setIsModalOpen(false);
      setReason("");
      setBody("");
   };


   const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
   };

   const handleDropdownItemClick = (section) => {
      setSelectedSection(section);
      setDropdownOpen(false);
   };


   const adminId = localStorage.getItem('theAdminId');


   const handleChange = (event) => {
      setEmployee(event.target.value);
   };

   useEffect(() => {
      const fetchEmployees = async () => {
         try {
            const response = await fetch(`${url}/all-employees/${adminId}`, {
               headers: {
                  Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                  "Content-Type": "application/json",
               },
            });

            const data = await response.json();
            console.log("Full API Response:", data);

            if (data.status === true) {
               setEmployees(data.data || []);
               console.log("Employee Data Set:", data.data);
            } else {
               console.error("Failed to fetch employees:", data.message);
            }
         } catch (error) {
            console.error("Error fetching employees:", error);
         } finally {
            setLoading(false);
         }
      };

      fetchEmployees();
   }, [adminId]);


   const handleAccept = async (leaveId) => {
      const confirmAccept = window.confirm("Are you sure you want to accept this leave?");
      if (confirmAccept) {
         try {
            console.log("Attempting to accept leave with leaveId:", leaveId);

            // Prepare the request payload
            const payload = {
               leaveId: leaveId,
               adminId: localStorage.getItem("theAdminId"),
               status: 1,  // 1 for accepted
            };
            console.log("Request payload for accepting leave:", payload);

            const response = await fetch(`${url}/accept-or-reject-leave`, {
               method: "POST",
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `bearer ${localStorage.getItem("authToken")}`,
               },
               body: JSON.stringify(payload),
            });

            console.log("Response received for accepting leave:", response);

            const data = await response.json();
            console.log("Parsed response data:", data);

            if (response.ok && data.status) {
               console.log("Leave accepted successfully:", data.data);
               toast.success("Leave request accepted successfully!");
               window.location.reload();
               setLeaveRequests((prev) =>
                  prev.map((leave) =>
                     leave._id === leaveId ? { ...leave, status: 1 } : leave
                  )
               );
            } else {
               console.error("Error accepting leave:", data.message);
               toast.error(data.message || "Failed to accept leave request.");
            }
         } catch (error) {
            console.error("Network error while accepting leave:", error);
            toast.error("Network error. Please try again later.");
         }
      }
   };

   const handleReject = async (leaveId) => {
      const confirmReject = window.confirm("Are you sure you want to reject this leave?");
      if (confirmReject) {
         try {
            console.log("Attempting to reject leave with leaveId:", leaveId);

            // Prepare the request payload
            const payload = {
               leaveId: leaveId,
               adminId: localStorage.getItem("theAdminId"),
               status: 2,  // 2 for rejected
            };
            console.log("Request payload for rejecting leave:", payload);

            const response = await fetch(`${url}/accept-or-reject-leave`, {
               method: "POST",
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `bearer ${localStorage.getItem("authToken")}`,
               },
               body: JSON.stringify(payload),
            });

            console.log("Response received for rejecting leave:", response);

            const data = await response.json();
            console.log("Parsed response data:", data);

            if (response.ok && data.status) {
               console.log("Leave rejected successfully:", data.data);
               toast.success("Leave request rejected successfully!");
               window.location.reload();
               setLeaveRequests((prev) =>
                  prev.map((leave) =>
                     leave._id === leaveId ? { ...leave, status: 2 } : leave
                  )
               );
            } else {
               console.error("Error rejecting leave:", data.message);
               toast.error(data.message || "Failed to reject leave request.");
            }
         } catch (error) {
            console.error("Network error while rejecting leave:", error);
            toast.error("Network error. Please try again later.");
         }
      }
   };






   const getButtonText = () => {
      switch (selectedSection) {
         case "new":
            return "New Request";
         case "approved":
            return "Approved";
         case "rejected":
            return "Rejected";
         default:
            return "Select an option";
      }
   };




   useEffect(() => {
      const fetchLeaveRequests = async () => {
         setLoading(true);
         try {
            const response = await fetch(`${url}/leave-request-all/${adminId}?groupId=${groupId}&employeeId=${employeeId}&date=${selectedDate}`, {
               headers: {
                  Authorization: `bearer ${localStorage.getItem("authToken")}`,
                  "Content-Type": "application/json",
               },
            });

            if (response.ok) {
               const data = await response.json();
               if (data.status) {
                  // Filter the data for different statuses
                  const newRequests = data.data.filter((leave) => leave.status === 0); // New
                  const acceptedRequests = data.data.filter((leave) => leave.status === 1); // Accepted
                  const rejectedRequests = data.data.filter((leave) => leave.status === 2); // Rejected

                  // Set the states accordingly
                  setLeaveRequests(newRequests);
                  setAcceptedLeaveRequests(acceptedRequests);
                  setRejectedLeaveRequests(rejectedRequests);
               } else {
                  console.log(data.message);
               }
            } else {
               console.log("Failed to fetch leave requests");
            }
         } catch (error) {
            console.error("Error fetching leave requests:", error);
         } finally {
            setLoading(false);
         }
      };

      fetchLeaveRequests();
   }, [adminId, groupId, employeeId, employeeeid2, selectedDate]);




   // const acceptedLeaveRequests = leaveRequests.filter((leave) => leave.status === 1);
   // const rejectedLeaveRequests = leaveRequests.filter((leave) => leave.status === 2);


   useEffect(() => {
      const fetchGroups = async () => {
         try {
            if (!adminId) {
               toast.error("Admin ID not found in local storage");
               return;
            }

            const response = await fetch(`${url}/get-all-groups/${adminId}`, {
               headers: {
                  "Content-Type": "application/json",
                  "Authorization": `bearer ${localStorage.getItem('authToken')}`
               }
            });

            const data = await response.json();
            console.log("Fetched groups data:", data);
            if (data.status) {
               setGroups(data.data);

               console.log(data)
            } else {
               toast.error(data.message);
            }
         } catch (error) {
            toast.error("Error fetching groups: " + error.message);
         } finally {
            setLoading(false)
         }
      };

      fetchGroups();
   }, [adminId]);

   const handleGroupFilter = (groupId) => {
      if (groupId) {
         setGroupId(groupId)
      } else {


      }
   };

   const handleEmployeeFilter = (groupId) => {
      if (groupId) {
         setEmployeeId(groupId)
      } else {

         //   fetchLeaveRequests();
      }
   };

   const handleClearEmployeeFilter = () => {
      setEmployeeId("null")
   };

   const handleClearGroupFilter = () => {
      setGroupId("null")
   };


   const handleClearDateFilter = () => {

   };




   return (
      <div className="flex w-full h-screen relative">
         {/* <SideMenu /> */}
         <div className="w-full max-sm:w-[100%] overflow-scroll scrollbar-hide px-3">
            <Navbar />
            <div className="lg:px-2 py-6 relative">
               <div className="flex items-center max-sm:mt-10 mt-10 relative">
                  <h1 className="text-2xl max-sm:text-xl font-semibold">Leave Management</h1>
                  <div className="relative">
                     <button
                        onClick={toggleDropdown}
                        className="p-1 ml-5 px-2 rounded-xl border border-gray-300 bg-white"
                     >
                        {getButtonText()} <ArrowDropDownIcon />
                     </button>
                     {dropdownOpen && (
                        <ul className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                           <li>
                              <button
                                 onClick={() => handleDropdownItemClick("new")}
                                 className={`block p-2 w-full text-left rounded-lg hover:bg-gray-200 ${selectedSection === "new" ? "bg-gray-200" : ""}`}
                              >
                                 New Request
                              </button>
                           </li>
                           <li>
                              <button
                                 onClick={() => handleDropdownItemClick("approved")}
                                 className={`block p-2 w-full text-left rounded-lg hover:bg-gray-200 ${selectedSection === "approved" ? "bg-gray-200" : ""}`}
                              >
                                 Approved
                              </button>
                           </li>
                           <li>
                              <button
                                 onClick={() => handleDropdownItemClick("rejected")}
                                 className={`block p-2 w-full text-left rounded-lg hover:bg-gray-200 ${selectedSection === "rejected" ? "bg-gray-200" : ""}`}
                              >
                                 Rejected
                              </button>
                           </li>
                        </ul>
                     )}
                  </div>
               </div>
               <hr className="mt-5" />
               <div className="mt-8">
                  <div className="lg:flex sm:flex justify-between">
                     <div>
                        {/* <h1 className="text-xl max-sm:text-lg font-bold max-sm:font-medium mb-2 max-sm:mb-0">New Leave Requests</h1>
                              <p className="mb-6 max-sm:mb-2 text-gray-600 max-sm:text-[12px]">This Is Sub Description</p> */}
                     </div>
                     <div className="flex max-sm:overflow-scroll scrollbar-hide space-x-3 max-sm:space-x-2 mb-6 font-medium text-[14px] max-sm:text-[12px]">
                        <div className="relative">
                           <select
                              value={employeeId || ""} // Use an empty string for the default option in the dropdown
                              className="appearance-none border border-black px-4 py-1 rounded-3xl focus:outline-none"
                              onChange={(e) => handleEmployeeFilter(e.target.value || null)} // Convert empty string to null for the backend
                           >
                              <option value="">Filter By Employee</option>
                              {employees.map((employee) => (
                                 <option key={employee._id} value={employee._id}>
                                    {employee.name || "Unnamed Employee"}
                                 </option>
                              ))}
                           </select>

                           {/* Conditionally render the cancel icon if an employee is selected */}
                           {employeeId && (
                              <MdClose
                                 onClick={() => {
                                    setEmployeeId(null); // Clear the employeeId by setting it to null
                                 }}
                                 className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer text-gray-500"
                                 size={20}
                              />
                           )}
                        </div>

                        <div className="relative">
                           <select
                              className="appearance-none border border-black px-4 py-1 rounded-3xl focus:outline-none"
                              value={groupId}
                              onChange={(e) => handleGroupFilter(e.target.value)}
                           >
                              <option value="">Filter By Group</option>
                              {groups.map((group) => (
                                 <option key={group._id} value={group._id}>
                                    {group?.groupName} {/* Adjust if your group data has a different property for the name */}
                                 </option>
                              ))}
                           </select>
                           {groupId && (
                              <MdClose
                                 onClick={() => {
                                    setGroupId("");
                                 }}
                                 className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer text-gray-500"
                                 size={20}
                              />
                           )}
                        </div>

                        <div className="relative">
                           {/* Label for the input */}
                           {!selectedDate && (
                              <label
                                 className="absolute bg-white left-4 top-1/2 transform -translate-y-1/2 text-gray-900 pointer-events-none"
                                 onClick={handleLabelClick}
                              >
                                 Filter By&nbsp;Date
                              </label>
                           )}

                           {/* Date input */}
                           <input
                              ref={inputRef}
                              type="date"
                              className={`!appearance-none border border-black px-4 py-1 rounded-3xl focus:outline-none cursor-pointer ${!selectedDate ? "text-gray-500" : "text-black"}`}
                              value={selectedDate}
                              onChange={(e) => setSelectedDate(e.target.value)}
                              onFocus={() => inputRef.current?.showPicker()}
                           />

                           {/* Clear icon */}
                           {selectedDate && (
                              <MdClose
                                 onClick={() => {
                                    setSelectedDate("");
                                 }}
                                 className="absolute top-1/2 right-4 z-50 bg-white transform -translate-y-1/2 cursor-pointer text-gray-500"
                                 size={20}
                              />
                           )}
                        </div>


                     </div>
                  </div>
                  {selectedSection === "new" && (
                     <div>
                        <h1 className="text-xl max-sm:text-lg font-bold max-sm:font-medium  max-sm:mt-5 max-sm:mb-0 -mt-10 mb-2">New Leave Requests</h1>

                        {/* New Leave Requests Table */}
                        <div className="">
                           <div className="overflow-x-auto">
                              <table className="w-full bg-white">
                                 <thead className="bg-gray-100 ">
                                    <tr>
                                       <th className="py-3 max-sm:py-0  max-sm:px-14 ">S.NO</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14 ">Emp Name</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Group</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Leave Dates</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">No of days</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Reason</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Attachments</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14"> Actions</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {loading ? (
                                       <tr>
                                          <td colSpan="8" className="text-center py-3">Loading...</td>
                                       </tr>
                                    ) : leaveRequests.length === 0 ? (
                                       <tr>
                                          <td colSpan="8" className="text-center py-3 text-red-500">No Leave request available</td>
                                       </tr>
                                    ) : (
                                       leaveRequests.map((leave, index) => (
                                          <tr key={index} className="border-b text-center">
                                             <td className="py-3 px-6">{index + 1}</td>
                                             <td className="py-3 px-6">{leave.employee?.name}</td>
                                             <td className="py-3 px-6">{leave.group?.groupName}</td>
                                             <td className="py-3 px-6">
                                                {new Date(leave.leaveFrom).toLocaleDateString()} TO {new Date(leave.leaveUpTo).toLocaleDateString()}
                                             </td>
                                             <td className="py-3 px-6">
                                                {Math.max(
                                                   1,
                                                   Math.ceil((new Date(leave.leaveUpTo) - new Date(leave.leaveFrom)) / (1000 * 60 * 60 * 24))
                                                )} Days
                                             </td>


                                             <td className="py-3 px-6 underline hover:text-blue-700 cursor-pointer">
                                                <a onClick={() => handleViewClick(leave.reason, leave.body)}>View</a>
                                             </td>

                                             <td className="py-3 px-6">
                                                {leave.attachment ? (
                                                   <Link to={leave.attachment} className="underline hover:text-blue-700 cursor-pointer">
                                                      Download
                                                   </Link>
                                                ) : (
                                                   <span className="text-gray-500">Not Available</span>
                                                )}
                                             </td>

                                             <td className="py-3 px-6 flex justify-center gap-2">
                                                <button onClick={() => handleAccept(leave)} style={{ fontFamily: 'Inder' }} className="bg-green-700 hover:bg-green-800 text-white px-6 py-1 max-sm:py-2 rounded-lg">
                                                   Accept
                                                </button>
                                                <button onClick={() => handleReject(leave)} style={{ fontFamily: 'Inder' }} className="bg-red-600 max-sm:hover:bg-red-700 text-white px-6 py-1 max-sm:py-2 rounded-lg">
                                                   Reject
                                                </button>
                                             </td>
                                          </tr>
                                       ))
                                    )}
                                 </tbody>
                              </table>
                              {/* <ReasonModal isOpen={isModalOpen} onClose={closeModal} reason={reason} /> */}
                           </div>
                        </div>
                     </div>
                  )}


                  {/* Accepted Leave Requests Table */}
                  {selectedSection === "approved" && (
                     <div>
                        <h1 className="text-xl max-sm:text-lg font-bold max-sm:font-medium mb-2  max-sm:mt-5  -mt-10 max-sm:mb-0">Accepted Leave Requests</h1>
                        <div className="">
                           <div className="overflow-x-auto">
                              <table className="w-full bg-white">
                                 <thead className="bg-gray-100 ">
                                    <tr>
                                       <th className="py-3 max-sm:py-0  max-sm:px-14 ">S.NO</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14 ">Emp Name</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Group</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Leave Dates</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">No of days</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Reason</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Attachments</th>
                                       {/* <th className="py-3 max-sm:py-0 max-sm:px-14"> Actions</th> */}
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {loading ? (
                                       <tr>
                                          <td colSpan="8" className="text-center py-3">Loading...</td>
                                       </tr>
                                    ) : acceptedLeaveRequests.length === 0 ? (
                                       <tr>
                                          <td colSpan="8" className="text-center py-3 text-red-500">No AcceptedLeave  available</td>
                                       </tr>
                                    ) : (
                                       acceptedLeaveRequests.map((leave, index) => (
                                          <tr key={index} className="border-b text-center">
                                             <td className="py-3 px-6">{index + 1}</td>
                                             <td className="py-3 px-6">{leave?.employee?.EmployeeName}</td>
                                             <td className="py-3 px-6">{leave?.group?.groupName}</td>
                                             <td className="py-3 px-6">
                                                {new Date(leave.leaveFrom).toLocaleDateString()} TO {new Date(leave.leaveUpTo).toLocaleDateString()}
                                             </td>
                                             <td className="py-3 px-6">
                                                {Math.max(
                                                   1,
                                                   Math.ceil((new Date(leave.leaveUpTo) - new Date(leave.leaveFrom)) / (1000 * 60 * 60 * 24))
                                                )} Days
                                             </td>

                                             <td className="py-3 px-6 underline hover:text-blue-700 cursor-pointer">
                                                <a onClick={() => handleViewClick(leave.reason, leave.body)}>View</a>
                                             </td>
                                             <td className="py-3 px-6">
                                                {leave.attachment ? (
                                                   <Link to={leave.attachment} className="underline hover:text-blue-700 cursor-pointer">
                                                      Download
                                                   </Link>
                                                ) : (
                                                   <span className="text-gray-500">Not Available</span>
                                                )}
                                             </td>

                                          </tr>
                                       ))
                                    )}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  )}

                  {/* Rejected Leave Requests Table */}
                  {selectedSection === "rejected" && (
                     <div>
                        <h1 className="text-xl max-sm:text-lg font-bold max-sm:font-medium mb-2  max-sm:mt-5  max-sm:mb-0 -mt-10">Rejected Leave Requests</h1>
                        <div className="">
                           <div className="overflow-x-auto">
                              <table className="w-full bg-white">
                                 <thead className="bg-gray-100 ">
                                    <tr>
                                       <th className="py-3 max-sm:py-0  max-sm:px-14 ">S.NO</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14 ">Emp Name</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Group</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Leave Dates</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">No of days</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Reason</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Attachments</th>
                                       {/* <th className="py-3 max-sm:py-0 max-sm:px-14"> Actions</th> */}
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {loading ? (
                                       <tr>
                                          <td colSpan="8" className="text-center py-3">Loading...</td>
                                       </tr>
                                    ) : rejectedLeaveRequests.length === 0 ? (
                                       <tr>
                                          <td colSpan="8" className="text-center py-3 text-red-500">No Leave request available</td>
                                       </tr>
                                    ) : (
                                       rejectedLeaveRequests.map((leave, index) => (
                                          <tr key={index} className="border-b text-center">
                                             <td className="py-3 px-6">{index + 1}</td>
                                             <td className="py-3 px-6">{leave.employee?.name}</td>
                                             <td className="py-3 px-6">{leave.group?.groupName}</td>
                                             <td className="py-3 px-6">
                                                {new Date(leave.leaveFrom).toLocaleDateString()} TO {new Date(leave.leaveUpTo).toLocaleDateString()}
                                             </td>
                                             <td className="py-3 px-6">
                                                {Math.max(
                                                   1,
                                                   Math.ceil((new Date(leave.leaveUpTo) - new Date(leave.leaveFrom)) / (1000 * 60 * 60 * 24))
                                                )} Days
                                             </td>

                                             <td className="py-3 px-6 underline hover:text-blue-700 cursor-pointer">
                                                <a onClick={() => handleViewClick(leave.reason, leave.body)}>View</a>

                                             </td>
                                             <td className="py-3 px-6">
                                                {leave.attachment ? (
                                                   <Link to={leave.attachment} className="underline hover:text-blue-700 cursor-pointer">
                                                      Download
                                                   </Link>
                                                ) : (
                                                   <span className="text-gray-500">Not Available</span>
                                                )}
                                             </td>

                                          </tr>
                                       ))
                                    )}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  )}
               </div>
               <ReasonModal isOpen={isModalOpen} onClose={closeModal} reason={reason} body={body} />
            </div>
         </div>
      </div>
   );
}

export default Leaves;
