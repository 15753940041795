import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import SideMenu from "../../Components/Sidebar/sidebar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { url } from "../../urlConfig";

function ManageProfile() {
  const [profileData, setProfileData] = useState({
    username: '',
    email: '',
    organisation: '',
    industry: '',
    activePlan: '',
    totalEmployees: 0,
  });
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const adminId = localStorage.getItem('theAdminId');

  useEffect(() => {
    const fetchProfileDetails = async () => {
      try {
        console.log('Fetching admin details...');
        const response = await fetch(`${url}/single-admin-details/${adminId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        console.log('Response status:', response.status);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Fetched data:', data);

        setProfileData({
          profileImage: data.data.profileImage || "",
          contactPersonName: data.data.contactPersonName || '',
          email: data.data.email || '',
          organizationName: data.data.organizationName || '',
          industry: data.data.industry || '',
          totalEmployees: data.data.numberOfEmployee || 0,
          activePlan: data.data.activePlan || ''
        });
        setNewUsername(data.data.contactPersonName || '');
        // localStorage.setItem("contactPerson", data.data.contactPersonName);
        // localStorage.setItem("profile", JSON.stringify(data.data.profileImage));
        // localStorage.setItem("email", data.data.email);

      } catch (error) {
        console.error('Error fetching profile details:', error);
      }
    };

    if (adminId) {
      fetchProfileDetails();
    } else {
      console.error('Admin ID is missing');
    }
  }, [adminId]);


  return (
    <>
      <div className="flex w-full h-screen">
        {/* <SideMenu /> */}
        <div className="w-[100%] max-sm:w-[100%] px-3  overflow-scroll  scrollbar-hide h-[100vh] ">
          {/* Header */}
          <div className="flex p-5 mt-5 max-sm:mt-10 max-sm:p-0 justify-between sticky max-sm:top-10 lg:top-0 bg-white cursor-pointer">
            <div>
              <h1 className="text-[22px] font-semibold">Manage Your Profile</h1>
              <p className="text-[16px] font-regular lg:mt-2">This is a sub description</p>
            </div>
            <div className="flex space-x-2 mr-3 max-sm:hidden">
              <img
                src={profileData?.profileImage || "../4Shifter-icon.png"}
                className="h-[40px] w-[40px] rounded-full"
                alt={profileData?.contactPersonName}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../4Shifter-icon.png";
                }}
              />
              <div className="text-[14px]">
                <h1 className="text-blue-700 font-semibold">{profileData.contactPersonName}</h1>
                <p>
                  {profileData.email}
                  <ExpandMoreIcon />
                </p>
              </div>
            </div>
          </div>

          {/* Personal Details */}
          <div className="p-5 max-sm:mt-5 max-sm:p-0">
            <h1 className="text-[22px] font-semibold  bg-white">Personal Details</h1>
            <div className="mt-5 lg:flex md:flex space-x-4 max-sm:space-x-0 ">
            <img
                src={profileData?.profileImage || "../4Shifter-icon.png"}
                className="h-20 w-20 rounded-full"
                alt={profileData?.contactPersonName}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../4Shifter-icon.png";
                }}
              />
              <div className="lg:flex lg:flex-col  space-y-4 max-sm:space-y-4 lg:w-full max-sm:w-[100%]  max-sm:mt-5 ">
                <div className="lg:flex md:flex space-x-4 max-sm:space-x-0 max-sm:space-y-5">
                  <div className="flex items-center space-x-2 py-2 border-2 border-gray-300 p-2  rounded-xl lg:w-[40%] max-sm:w-[100%] ">
                    <AccountCircleOutlinedIcon />
                    <div className="border-r-2 border-dashed border-gray-400 h-6"></div>
                    <div className=" pl-2 w-full ">
                      <p className="text-gray-400 text-[14px] font-light">Username</p>
                      <input
                        type="text"
                        value={profileData.contactPersonName}
                        readOnly
                        className="bg-transparent focus:outline-none w-full font-medium text-gray-600"
                      />
                    </div>
                  </div>
                  <div className="flex items-center space-x-2 border-2 border-gray-300  p-2 rounded-xl w-[40%] max-sm:w-[100%] ">
                    <EmailOutlinedIcon />
                    <div className="border-r-2 border-dashed border-gray-400 h-6"></div>
                    <div className=" pl-2 w-full">
                      <p className="text-gray-400  text-[14px] font-light">Email Address</p>
                      <input
                        type="text"
                        value={profileData.email}
                        readOnly
                        id="email"
                        className="bg-transparent focus:outline-none w-full font-medium text-gray-600"
                      />
                    </div>
                  </div>
                </div>
                <div className="lg:flex md:flex space-x-4 max-sm:space-x-0 max-sm:space-y-5">
                  <div className="flex items-center space-x-2 border-2 border-gray-300 p-2 rounded-xl w-[40%] max-sm:w-[100%]">
                    <BusinessOutlinedIcon />
                    <div className="border-r-2 border-dashed border-gray-400 h-6"></div>
                    <div className=" pl-2 w-full">
                      <p className="text-gray-400  text-[14px] font-light">Organization Name</p>
                      <input
                        type="text"
                        value={profileData.organizationName}
                        readOnly
                        id="organisation"
                        className="bg-transparent focus:outline-none w-full font-medium text-gray-600"
                      />
                    </div>
                  </div>
                  <div className="flex items-center space-x-2 border-2 border-gray-300 p-2 rounded-xl w-[40%] max-sm:w-[100%]">
                    <WorkOutlineOutlinedIcon />
                    <div className="border-r-2 border-dashed border-gray-400 h-6"></div>
                    <div className=" pl-2 w-full">
                      <p className="text-gray-400  text-[14px] font-light">Industry</p>
                      <input
                        type="text"
                        value={profileData.industry}
                        readOnly
                        className="bg-transparent focus:outline-none w-full font-medium text-gray-600"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Subscription Details */}
          <div className="p-5 max-sm:p-0 max-sm:mt-6 lg:-mt-5 ">
            <h1 className="text-[22px] font-semibold">Subscription Details</h1>
            <div className="mt-5 lg:flex md:flex items-center lg:space-x-4 md:space-x-4 max-sm:space-y-3" >
              <div className="border-2 border-green-300 p-4 max-sm:p-2 rounded-md w-[30%] max-sm:w-[100%]">
                <div className="text-[18px] font-semibold">Active Plan - {profileData.activePlan || "None"}</div>
                {/* <div className="text-[18px] font-regural mt-2">5,50 € per user/month</div> */}
                <div className="text-[18px] font-regural mt-2">___________</div>
              </div>
              <div className="border-2 border-green-300 p-4 max-sm:p-2 rounded-md w-[30%] max-sm:w-[100%]">
                <div className="text-[18px] font-semibold">Total Employees</div>
                <div className="text-[18px] font-regural mt-2">{profileData.totalEmployees}</div>
              </div>
              &nbsp; &nbsp;
              <NavLink to="/profile/Subscription" className="bg-blue-700 max-sm:hidden   text-white px-14 py-2 mb-14 rounded-md max-sm:-ml-3">
                Upgrade Plan
              </NavLink>
            </div>
            <NavLink to="/profile/Subscription" style={{ fontFamily: 'Inder' }} className="bg-blue-700 md:hidden lg:hidden  text-white px-14 py-2 mb-14 rounded-md ">
              Upgrade Plan
            </NavLink>
          </div>

          {/* Settings */}
          <div className="p-5 max-sm:p-0 max-sm:mt-10 lg:-mt-5">
            <h1 className="text-[22px] font-semibold">Settings</h1>
            <div className="mt-5 space-y-5">
              <a className="text-[16px] font-medium cursor-pointer ">Update Email ID</a>
              <div className="border-t border-dashed border-gray-300 pt-4  w-[30%] max-sm:w-auto "></div>
              <a className="text-[16px] font-medium cursor-pointer">Update User Name</a>
              <div className="border-t border-dashed border-gray-300 pt-4 w-[30%] max-sm:w-auto "></div>
              <a className="text-[16px] font-medium cursor-pointer">German Working Laws</a>
              <div className="border-t border-dashed border-gray-300 pt-4 w-[30%] max-sm:w-auto "></div>
              <a className="text-[16px] font-medium cursor-pointer">Cancel Subscription</a>
              <div className="border-t border-dashed border-gray-300 pt-4 w-[30%] max-sm:w-auto "></div>
              <a className="text-[16px] font-medium cursor-pointer">View Billings</a>
              <div className="border-t border-dashed border-gray-300 pt-4 w-[30%] max-sm:w-auto "></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageProfile;
