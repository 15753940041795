
  function ReasonModal({ isOpen, onClose, reason,body }) {
    if (!isOpen) return null;
    return (  
<div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg lg:w-1/3 max-sm:w-[90%]">
          <h2 className="text-xl font-semibold text-center mb-5">Leave Reason:</h2>
          <p className="mt-10 mb-5 text-[20px] font-medium">{reason || "hii this is leave reason"}</p>
          <p className="mt-5 mb-5">{body || "hii this is leave reason body"}</p>
          <div className="mt-auto flex justify-end">
          <button 
            className="px-4 py-2 bg-red-500 hover:bg-red-400 text-white rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>
        </div>
      </div>
    );
  }
  
  export default ReasonModal;
  