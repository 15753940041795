import { useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { url } from '../../urlConfig';

function EmployeesCard({ employee, onEdit, setEmployees, employees }) {
    const [isDeleted, setIsDeleted] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleDelete = async () => {
        try {
            const adminId = localStorage.getItem('theAdminId');
            const response = await fetch(`${url}/delete-employee`, {
                method: 'DELETE',
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('authToken')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    employeeId: employee._id,
                    adminId: adminId,
                }),
            });

            const data = await response.json();

            if (data.status === true) {
                toast.success(data.message);
                const updatedEmployees = employees.filter((emp) => emp._id !== data.data._id);
                setEmployees(updatedEmployees);
                setModalOpen(false);
                toast.success("Employee deleted successfully")
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error("Error deleting employee:", error);
            toast.error("An error occurred while deleting the employee.");
        }
    };

    return (
        <>
            <div className="bg-[#f5f3f3] lg:w-[31%] sm:w-[45%] max-sm:w-full h-[160px] hover:shadow-sm rounded-xl">
                <div className="flex justify-between">
                    <button
                        className="text-[16px] font-normal text-blue-700 border border-gray-300 px-5 py-1 bg-white hover:bg-gray-100"
                        style={{ borderRadius: "10px 0 10px 0" }}
                        onClick={onEdit}
                    >
                        <EditOutlinedIcon style={{ fontSize: "18px", marginBottom: "4px", marginRight: "5px" }} />
                        Edit
                    </button>
                    <button
                        className="text-[16px] font-normal text-white border border-gray-300 px-5 py-1 bg-[#FD474D] hover:bg-red-600"
                        style={{ borderRadius: "0 10px 0 10px" }}
                        onClick={handleOpenModal}
                    >
                        <DeleteIcon style={{ fontSize: "18px", marginBottom: "4px", marginRight: "5px" }} />
                        Delete
                    </button>
                </div>
                <div className="flex p-4 space-x-3 mr-3">
                    <img
                        src={employee?.profileImage || `${process.env.PUBLIC_URL}/4Shifter-icon.png`}
                        className="h-[40px] w-[40px] rounded-full"
                        alt={employee?.name}
                        onError={(e) => {
                            e.target.onerror = null; 
                            e.target.src = `${process.env.PUBLIC_URL}/4Shifter-icon.png`; 
                        }}
                    />
                    <div className="text-[14px]">
                        <h1 className="text-[#375E7F] font-medium">{employee?.name}</h1>
                        <p className="text-[14px] font-medium">{employee?.email}</p>
                    </div>
                </div>
                <div className="border-t border-dashed border-gray-600 w-[90%] ms-4 my-2"></div>
                <div className="px-5 flex justify-between mb-2 text-[14px] font-medium">
                    <p>Group Name: {employee?.group?.groupName || "N/A"}</p>
                    <p>{employee?.phone || "+0000000000"}</p>
                </div>
            </div>

            <Dialog 
    open={modalOpen} 
    onClose={handleCloseModal} 
    sx={{ '& .MuiPaper-root': { minHeight: '200px', maxHeight: '300px', borderRadius:"20px" } }} 
>
    <DialogContent sx={{ fontSize: "22px", padding: "40px 20px" }} className="text-center font-semibold">
        Are you sure you want to delete this employee permanently?
    </DialogContent>
    <DialogActions className="flex lg:mr-32 lg:gap-5 mb-5">
        <button onClick={handleDelete} className="border border-green-500 text-[18px] font-semibold bg-[#FD474D] text-white px-16 py-2 rounded-2xl hover:bg-white hover:text-black">
            Yes
        </button>
        <button onClick={handleCloseModal} className="border border-green-500 bg-[#FD474D] text-[18px] font-semibold text-white px-16 py-2 rounded-2xl hover:bg-white hover:text-black">
            No
        </button>
    </DialogActions>
</Dialog>


            <ToastContainer />
        </>
    );
}

export default EmployeesCard;
