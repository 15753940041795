import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
// import { debounce } from "lodash";

// import { ToastContainer, toast } from 'react-toastify';
function CalenderBox({
  dates,
  shiftListData,
  repeatAfter ,
  setRepeatAfter,
  customDates,
  setCustomDates,
  isEditingDate,
  setIsEditingDate,
  isEditingSchedule,
  setIsEditingSchedule,
  selectedShift,
  shift,
  setShift,
  isScheduled,
  groupId,
  schedule,
  setShiftListData,
  setMonth,
  staticShiftListData,
  shiftListData2,
  setShiftListData2,
  month,
  shiftListData3,
  totalMonthDays,
}) {
  const [shiftStartFrom, setShiftStartFrom] = useState("");
  const [shiftEndOn, setShiftEndOn] = useState("");
  const [repeatFrom, setRepeatFrom] = useState("");
  // const [repeatAfter, setRepeatAfter] = useState();
  const [shiftSmallDate, setShiftSmallDate] = useState("");
  const [shiftLargeDate, setShiftLargeDate] = useState("");
  // const [staticShiftListData,setStaticShiftListData]=useState([])
  // let repeatAfter= localStorage.getItem("repeatAfter")
  const [counter, setCounter] = useState(0);
  const hasRunRef = useRef(false);
  
  const [timeToRun, setTimeToRun] = useState(
    Math.ceil(
      totalMonthDays.length / (shiftListData3.length + Number(repeatAfter))
    )
  );
  console.log({"repeatAfter":repeatAfter})

  const MAX_SHIFT_LIST_SIZE = 10000; // Example limit

  const runUpdate = async () => {
    // setShiftListData2([])
    let updatedData = [...shiftListData2];
    const maxIterations = 15;
    // const counter = Math.min(
    //   Math.floor(totalMonthDays.length / (shiftListData3.length + repeatAfter)),
    //   maxIterations
    // );
  
    for (let i = 0; i < 15; i++) {
      const newDates = await updateShiftDates(updatedData);
  
      if (!newDates || newDates.length === 0) {
        console.warn("No new dates generated. Stopping early.");
        break;
      }
  
      updatedData = [...updatedData, ...newDates];
  
      // Limit the size of updatedData
      if (updatedData.length > MAX_SHIFT_LIST_SIZE) {
        console.warn("Shift list size exceeded the limit. Truncating.");
        updatedData = updatedData.slice(0, MAX_SHIFT_LIST_SIZE);
        break;
      }
  
      // await new Promise((resolve) => setTimeout(resolve, 100)); // Delay
    }
  
    setShiftListData2(updatedData); // Batch state updates
  };
  
  
      const memoizedSchedule = useMemo(() => schedule, [schedule]);
      useEffect(() => {
        // console.log({"repearafter":repeatAfter})
        if (repeatAfter && !hasRunRef.current && schedule) {
          if(repeatAfter!==0){
            runUpdate();
          }
        
        }else{
          console.log({"repeatafter":repeatAfter})
        }
      }, [schedule,groupId ]);
// Ensure dependencies are correctly set

  const updateShiftDates = async (currentData) => {
    try {
      if (currentData.length > 0) {
        const allDates = currentData
          .map((shift) => {
            const dateKey = Object.keys(shift)[0];
            const parsedDate = new Date(dateKey);
            return !isNaN(parsedDate) ? dateKey : null; // Include only valid dates
          })
          .filter(Boolean); // Remove invalid dates
  
        if (allDates.length > 0) {
          allDates.sort((a, b) => new Date(a) - new Date(b));
          const lastDate = new Date(allDates[allDates.length - 1]);
  
          // Generate new dates based on the logic
          const newDates = await repeatDates(
            shiftListData2, // Original state
            addDaysToDate(lastDate, Number(repeatAfter) + 1),
            1
          );
  
          console.log("New Dates:", newDates);
          return newDates; // Return new data to append
        }
      }
    } catch (error) {
      console.error("Error in updateShiftDates:", error);
    }
    return [];
  };
  

  // const addDaysToDate = (date, days) => {
  //   const result = new Date(date);
  //   result.setDate(result.getDate() + days);
  //   return result;
  // };

  // const debouncedHandleDateClick = useCallback(
  //   debounce((dates) => {
  //     handleDateClick(dates);
  //   }, 300), // Adjust debounce time as needed
  //   []
  // );

 const getShiftColor = useMemo(() => {
  return (date) => {
    const customDateEntry = customDates.find(
      (customDate) => Object.keys(customDate)[0] === date
    );
    if (customDateEntry) {
      const customShiftName = Object.values(customDateEntry)[0];
      switch (customShiftName) {
        case "morning":
          return "bg-[#009011]"; // Green for morning
        case "afternoon":
          return "bg-[#FAC710]"; // Yellow for afternoon
        case "night":
          return "bg-[#FD474D]"; // Red for night
        case "holiday":
          return "bg-gray-500"; // Gray for holiday
        default:
          return "bg-[#25CCF7]"; // Default color
      }
    }
  
    // Fall back to shiftListData2 if no entry in customDates
    const shiftEntry = shiftListData2.find(
      (shift) => Object.keys(shift)[0] === date
    );
  
    if (!shiftEntry) return ""; // No color if date is not in any shift
  
    const shiftName = Object.values(shiftEntry)[0];
    switch (shiftName) {
      case "morning":
        return "bg-[#009011]"; // Green for morning
      case "afternoon":
        return "bg-[#FAC710]"; // Yellow for afternoon
      case "night":
        return "bg-[#FD474D]"; // Red for night
      case "holiday":
        return "bg-gray-500"; // Gray for holiday
      default:
        return "bg-[#25CCF7]"; // Default color
    }
  };
}, [customDates, shiftListData2]); // Dependencies: only update when these change


  const getShiftColor2 = (date) => {
    const shiftEntry = shift.find((shift) => Object.keys(shift)[0] === date);
    if (!shiftEntry) return ""; // No color if date is not in any shift

    const shiftName = Object.values(shiftEntry)[0];
    switch (shiftName) {
      case "morning":
        return "bg-[#009011]";
      case "afternoon":
        return "bg-[#FAC710]";
      case "night":
        return "bg-[#FD474D]";
      default:
        return "bg-[#25CCF7]"; // Default color for any other shift
    }
  };

  function addDaysToDate(date, count) {
    const newDate = new Date(date); // Convert the date string or Date object to a Date object
    if (isNaN(newDate.getTime())) {
      console.error("Invalid date:", date);
      return null; // or handle error accordingly
    }
    newDate.setDate(newDate.getDate() + count); // Add days to the date
    return newDate; // Return the new Date object
  }

  function repeatDates(originalDates, repeatFrom, count) {
    // Convert originalDates format to an array of date strings and shifts
  
    const dateShiftArray = originalDates.map((obj) => ({
      date: Object.keys(obj)[0],
      shift: Object.values(obj)[0],
    }));

    const newDates = dateShiftArray.map(({ date, shift }) => {
      const originalDate = new Date(date);
      const diffInTime =
        originalDate.getTime() - new Date(dateShiftArray[0].date).getTime(); // Difference in milliseconds
      const diffInDays = diffInTime / (1000 * 3600 * 24); // Convert milliseconds to days

      const newDate = new Date(repeatFrom);
      newDate.setDate(newDate.getDate() + diffInDays); // Add the difference in days
      const formattedDate = newDate.toISOString().split("T")[0]; // Format to YYYY-MM-DD

      return { [formattedDate]: shift }; // Return in the original format with date key and shift value
    });

    return newDates;
  }

  function handleDateClick(dates) {
    if (selectedShift === "") {
      toast.error("Select shift first");
    } else {
      const newShift = { [dates]: selectedShift };

      setCustomDates((prevData) => {
        // Check if the shift for the given date already exists
        const shiftIndex = prevData.findIndex(
          (shift) => Object.keys(shift)[0] === dates
        );
      
        // Update the data accordingly
        let updatedData;
      
        if (shiftIndex !== -1) {
          // If shift exists, update the shift to "holiday"
          updatedData = [...prevData];
          updatedData[shiftIndex] = { [dates]: selectedShift };
        } else {
          // If shift doesn't exist, add the new shift
          updatedData = [...prevData, newShift];
        }
      
        // Sort the updated array by date
        updatedData.sort(
          (a, b) => new Date(Object.keys(a)[0]) - new Date(Object.keys(b)[0])
        );
      
        console.log("Updated Data:", updatedData);
      setShift(updatedData)
        return updatedData;
      });
    }
  }
  function handleDateClick2(dates) {
    if (selectedShift === "") {
      toast.error("Select shift first");
    } else {
      const newShift = { [dates]: selectedShift };

      setShift((prevData) => {
        // Check if the shift for the given date already exists
        const shiftIndex = prevData.findIndex(
          (shift) => Object.keys(shift)[0] === dates
        );
      
        // Update the data accordingly
        let updatedData;
      
        if (shiftIndex !== -1) {
          // If shift exists, update the shift to "holiday"
          updatedData = [...prevData];
          updatedData[shiftIndex] = { [dates]: selectedShift };
        } else {
          // If shift doesn't exist, add the new shift
          updatedData = [...prevData, newShift];
        }
      
        // Sort the updated array by date
        updatedData.sort(
          (a, b) => new Date(Object.keys(a)[0]) - new Date(Object.keys(b)[0])
        );
      
        console.log("Updated Data:", updatedData);
      
        return updatedData;
      });
    }
  }
  function handleRightClick(e,dates){
    e.preventDefault();
  const result=  window.confirm("Are you sure to mark this date as holiday?") 
  if(result){
    const newShift = { [dates]: "holiday" };

    setCustomDates((prevData) => {
      // Check if the shift for the given date already exists
      const shiftIndex = prevData.findIndex(
        (shift) => Object.keys(shift)[0] === dates
      );
    
      // Update the data accordingly
      let updatedData;
    
      if (shiftIndex !== -1) {
        // If shift exists, update the shift to "holiday"
        updatedData = [...prevData];
        updatedData[shiftIndex] = { [dates]: "holiday" };
      } else {
        // If shift doesn't exist, add the new shift
        updatedData = [...prevData, newShift];
      }
    
      // Sort the updated array by date
      updatedData.sort(
        (a, b) => new Date(Object.keys(a)[0]) - new Date(Object.keys(b)[0])
      );
    
      console.log("Updated Data:", updatedData);
    
      return updatedData;
    });
      
  }
  }

  return (
    <>
    
      {isEditingDate?
      
        <div
          key={dates}
          onClick={() => handleDateClick(dates)}
          onContextMenu={(e)=>{handleRightClick(e,dates)}}
          className={`flex w-[55px] cursor-pointer h-[55px] items-center justify-center border-2 border-gray-800 ${getShiftColor(
            dates
          )}`} // Dynamic background color
        >
          {dates === " " ? <div> </div> : new Date(dates).getDate()}
        </div> 
        :
        <>
        <div>
        <div
          key={dates}
            onClick={() => handleDateClick(dates)}
          className={`flex w-[55px] cursor-pointer h-[55px] items-center justify-center border-2  border-gray-800 ${getShiftColor(
            dates
          )}`} // Dynamic background color
        >
          {dates === " " ? <div> </div> : new Date(dates).getDate()}
         
        </div>
        
        </div>

         
       </>
    }
        
{/* <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">

</div> */}
       
       
    </>
  );
}

export default CalenderBox;
