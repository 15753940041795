import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Navbar from "../../Components/Navbar/navbar";
import SideMenu from "../../Components/Sidebar/sidebar";
import TimePicker from '../../Components/TimePicker';
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { CircularProgress } from "@mui/material";
import { url } from '../../urlConfig';
import TimePicker2 from '../../Components/TimePicker';
import TimePicker3 from '../../Components/TimePicker';

function AllShift() {
   const [shifts, setShifts] = useState([]);
   const [shiftType, setShiftType] = useState('');
   const [title, setTitle] = useState('');
   const [startTime, setStartTime] = useState(null);
   const [endTime, setEndTime] = useState(null);
   const [editingShift, setEditingShift] = useState(null);
   const [load, setLoad] = useState(true);

   const adminId = localStorage.getItem('theAdminId');
   const colors = ['bg-[#6C757D]', 'bg-[#FF5733]', 'bg-[#33FF57]', 'bg-[#3357FF]', 'bg-[#FFC300]', 'bg-[#C70039]', 'bg-[#900C3F]', 'bg-[#581845]'];

   useEffect(() => {
      const fetchShifts = async () => {
         try {
            const response = await fetch(`${url}/get-all-shifts/${adminId}`, {
               method: "GET",
               headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem('authToken')}`
               }
            });

            const data = await response.json();
            if (data.status) {
               const formattedShifts = data.data.map(shift => ({
                  id: shift._id,
                  shiftName: shift.shiftName,
                  start: dayjs(shift.start, 'HH:mm').format('HH:mm'),
                  end: dayjs(shift.end, 'HH:mm').format('HH:mm'),
               }));
               setShifts(formattedShifts);
            }
         } catch (error) {
            console.error("Error fetching shifts:", error);
            toast.error("Failed to fetch shifts. Please try again.");
         } finally {
            setLoad(false);
         }
      };

      fetchShifts();
   }, [adminId]);

   const handleShiftTypeChange = (event) => {
      setShiftType(event.target.value);
      if (event.target.value !== 'Custom Shift') {
         setTitle('');
      }
   };

   const handleStartTimeChange = (newValue) => {
      setStartTime(newValue);
   };

   const handleEndTimeChange = (newValue) => {
      setEndTime(newValue);
   };

   const validateShift = () => {
      if (!shiftType) {
         toast.error("Please select a shift type.");
         return false;
      }
      if (shiftType === 'Custom Shift' && !title.trim()) {
         toast.error("Please enter a title for the custom shift.");
         return false;
      }
      if (!startTime || !endTime) {
         toast.error("Please select valid start and end times.");
         return false;
      }
      // if (startTime.isAfter(endTime)) {
      //    toast.error("Start time cannot be later than end time.");
      //    return false;
      // }
      return true;
   };


   const handleSaveShift = async () => {
      if (!validateShift()) return;
      const restrictedTitles = ['morning', 'afternoon', 'night'];

      if (shiftType === 'Custom Shift' && restrictedTitles.includes(title.toLowerCase())) {
         toast.error('Custom Shift cannot be named "Morning", "Afternoon", or "Night".');
         return;
      }

      if (shiftType === 'morning' || shiftType === 'afternoon' || shiftType === 'night') {
         const existingShift = shifts.find(shift => shift.shiftName === shiftType);
         if (existingShift && !editingShift) {
            toast.error(`${shiftType.charAt(0).toUpperCase() + shiftType.slice(1)} shift already exists. You cannot create another one.`);
            return;
         }
      }

      const formattedStartTime = startTime.format('HH:mm');
      const formattedEndTime = endTime.format('HH:mm');

      const shiftName = shiftType === 'Custom Shift' ? title : shiftType;

      try {
         const token = localStorage.getItem('authToken');
         if (!token) {
            toast.error("Token is missing");
            return;
         }

         const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
         };

         const requestBody = {
            adminId,
            shiftName,
            start: formattedStartTime,
            end: formattedEndTime,
         };

         const url2 = editingShift ? `${url}/edit-shift/${adminId}` : `${url}/add-shifts`;

         const response = await fetch(url2, {
            method: editingShift ? "PUT" : "POST",
            headers: headers,
            body: JSON.stringify({ ...requestBody, shiftId: editingShift }),
         });

         const data = await response.json();

         if (response.status === 401) {
            toast.error("You are not authorized to perform this action. Please login again.");
            return;
         }

         if (data.status) {
            toast.success(editingShift ? "Shift updated successfully!" : "Shift created successfully!");
            window.location.reload();
         } else {
            toast.error(data.message);
         }
      } catch (error) {
         console.error("Error during shift creation:", error);
         toast.error("Failed to save the shift. Please try again.");
      }

      setShiftType('');
      setTitle('');
      setStartTime(null);
      setEndTime(null);
      setEditingShift(null);
   };



   const handleEditShift = (shift) => {
      setStartTime(dayjs(shift.start, 'HH:mm'));
      setEndTime(dayjs(shift.end, 'HH:mm'));
      setEditingShift(shift.id);

      if (shift.shiftName !== 'morning' && shift.shiftName !== 'afternoon' && shift.shiftName !== 'night') {
         setShiftType('Custom Shift');
         setTitle(shift.shiftName || '');
      } else {
         setShiftType(shift.shiftName);
         setTitle('');
      }
   };


   const handleDeleteShift = async (shiftId) => {

      const shiftToDelete = shifts.find(shift => shift.id === shiftId);

      // Prevent deletion of predefined shifts
      if (shiftToDelete?.shiftName === 'morning' || shiftToDelete?.shiftName === 'afternoon' || shiftToDelete?.shiftName === 'night') {
         toast.error(`You cannot delete the ${shiftToDelete.shiftName} shift.`);
         return;
      }
      const confirmDelete = window.confirm("Are you sure you want to delete this shift?");
      if (!confirmDelete) {
         return;
      }

      const token = localStorage.getItem('authToken');
      try {
         const response = await fetch(`${url}/delete-shift/${adminId}`, {
            method: "DELETE",
            headers: {
               "Content-Type": "application/json",
               "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
               adminId,
               shiftId,
            })
         });

         const data = await response.json();

         if (data.status === true) {
            toast.success("Shift deleted successfully!");
            window.location.reload();

         } else {
            toast.error(data.message);
         }
      } catch (error) {
         console.error("Error deleting shift:", error);
         toast.error("Failed to delete shift. Please try again.");
      }
   };





   return (
      <div className="flex w-full h-screen ">
         {/* <SideMenu /> */}
         <div className=" md:w-full  px-3 overflow-scroll  scrollbar-hide">
            <Navbar />
            {/* <div className="lg:-ml-0 mt-10 sticky top-28 bg-white max-sm:mt-5 space-x-8 sm:ml-5">
               <input
                  style={{ fontFamily: 'Montserrat' }}
                  className='w-[400px] h-12 rounded-xl bg-[#f5f3f3] outline-none pl-4 mb-5'
                  placeholder='Search here ....'
               />
            </div> */}
            <ToastContainer />

            <div className="lg:flex mt-10 lg:mt-10 md:mt-0 lg:-ml-8">
               {/* All Shifts */}
               <div className="p-10 lg:-mt-8  max-sm:p-0 max-sm:mt-12 lg:w-[50%]    ">
                  <h1 className="text-[22px] font-bold">Shifts</h1>
                  <p style={{ fontFamily: "inter" }}>Current Shifts are Here</p>
                  {load ? (
                     <div className="flex justify-center text-center mt-10 font-bold text-lg">
                        <HourglassEmptyIcon className="animate-spin" /> Loading...
                     </div>
                  ) : shifts.length === 0 ? (
                     <p className="text-center mt-10 font-semibold italic">No shifts Created Yet</p>
                  ) : (
                     shifts.map(shift => (
                        <div key={shift.id} onClick={() => handleEditShift(shift)} className="flex mt-6 lg:space-x-0 max-sm:justify-between sm:justify-between">
                           <p
                              className={`${shift?.shiftName === 'morning' ? 'bg-[#009011]' :
                                 shift?.shiftName === 'afternoon' ? 'bg-[#FAC710]' :
                                    shift?.shiftName === 'night' ? 'bg-[#FD474D]' : 'bg-[#25CCF7]'} 
             lg:w-[83%] max-sm:w-[80%] sm:w-[80%] text-center py-3 max-sm:px-0 sm:px-10 
             cursor-pointer text-white capitalize`}
                           >
                              {shift.shiftName} &nbsp; {shift.start} - {shift.end}
                           </p>

                           <div className="flex space-x-4">
                              <button onClick={() => handleEditShift(shift)} className='btn'>
                                 <BorderColorOutlinedIcon />
                              </button>

                              <button onClick={() => handleDeleteShift(shift.id)} className='btn'>
                                 <DeleteOutlinedIcon />
                              </button>
                           </div>
                        </div>
                     ))
                  )}
               </div>
               <div className="border border-gray-300 h-auto mt-2"></div>
               {/* Create Shift */}
               <div className="p-10 lg:-mt-10 max-sm:p-0 max-sm:mt-5 max-sm:mb-5 lg:w-[60%] md:w-full">
                  <h1 className="text-[22px] font-semibold">Create New Shift</h1>
                  <p className=' capitalize'>Easily create a new time shift from here</p>
                  <div className=" mt-5 bg-white space-y-5 ">
                     <FormControl fullWidth className="mb-4">
                        <InputLabel>Shift Type</InputLabel>
                        <Select
                           value={shiftType}
                           onChange={handleShiftTypeChange}
                           label="Shift Type"
                        >
                           <MenuItem value={'Custom Shift'}>Custom Shift</MenuItem>
                           <MenuItem value={'morning'}>Morning Shift</MenuItem>
                           <MenuItem value={'afternoon'}>Afternoon Shift</MenuItem>
                           <MenuItem value={'night'}>Night Shift</MenuItem>
                        </Select>
                     </FormControl>

                     {shiftType === 'Custom Shift' && (
                        <TextField
                           fullWidth
                           label="Title"
                           value={title}
                           onChange={(e) => setTitle(e.target.value)}
                           className="mb-4"
                        />
                     )}

                     <div className='flex gap-20'>
                        <FormControl fullWidth className="mb-4">
                           <label>Shift Start Time</label>
                           <TimePicker3 value={startTime} onChange={handleStartTimeChange} />
                        </FormControl>

                        <FormControl fullWidth className="mb-4">
                           <label>Shift End Time</label>
                           <TimePicker3 value={endTime} onChange={handleEndTimeChange} />
                        </FormControl>
                     </div>





                     <button
                        fullWidth
                        className="font-medium  bg-blue-600 py-2 rounded-xl lg:w-[100%] max-sm:w-[100%] sm:w-[100%] text-white"
                        onClick={handleSaveShift}
                     >
                        {editingShift ? 'Update' : 'Save'}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default AllShift;
