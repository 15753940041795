import React, { useState, useEffect } from "react";

function AllChats({ name, messages = [], profileImage, unreadCount, lastMessage, unread }) {
  const [lastMessageTime, setLastMessageTime] = useState("");

  console.log("unread", unread)
  // Function to truncate the message
  const truncateMessage = (msg, wordLimit) => {
    const words = msg?.split(" ");
    return words?.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : msg;
  };

  // Get the time of the last message
  useEffect(() => {
    if (messages && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      setLastMessageTime(lastMessage.time);
    }
  }, [messages]); // This effect runs whenever the messages change

  return (
    <div className="flex cursor-pointer justify-between hover:shadow-lg bg-[#EDEDED] rounded-xl py-1 px-2 lg:w-[100%]">
      <div className="flex items-center w-[90%]">
      <img src={profileImage || "../4Shifter-icon.png"} className="w-14 h-14 rounded-full" />
      <div className="ml-5 mt-1 lg:w-[40%] md:w-[60%] max-sm:w-full">
        <h1 className="font-medium">{name}</h1>
        <p className="text-[14px] font-light">
          {truncateMessage(lastMessage?.content, 4)}
        </p>
      </div>
      </div>
      
      <div className="w-[10%]">
        {/* Show unread count if any unread messages */}
        {unread?.length > 0 && (
          <p className="bg-[#43C595] rounded-full text-white text-center w-6">
            {unread?.length}
          </p>
        )}
        {/* Display the last message time */}
        {lastMessage && (
          <p className="text-[#43C595] text-[12px] font-light block text-right mt-6 max-sm:text-sm -ml-5 ">
            {new Date(lastMessage.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
          </p>

        )}


      </div>
    </div>
  );

}


export default AllChats;
