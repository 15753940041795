import { NavLink, useNavigate } from "react-router-dom";
import SideMenu from "../../Components/Sidebar/sidebar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify"; 
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { url } from "../../urlConfig";



function SpecialShift() {
    const navigate = useNavigate();
    const [shifts, setShifts] = useState([]); 
    const [load,setLoad]=useState(true)
    const [specialId,setSpecialId]=useState("")
    const handleEdit = (shiftId) => {
        setSpecialId(shiftId)
        navigate(`/create-special-shift/${shiftId}`);
    };

    const handleView = (shift) => {
        navigate("/special-shift-detail", { state: { shift } });
    };

    const handleProfileClick = () => {
        console.log("Redirecting to profile page");
        navigate('/profile');
    };

    const adminId = localStorage.getItem('theAdminId'); 
    
    const profileImage = localStorage.getItem("profile");
    const contactPerson = localStorage.getItem("contactPerson") || "Admin";
    const email = localStorage.getItem("email") || "default@example.com";

    const parsedProfileImage = profileImage ? JSON.parse(profileImage) : null;

    useEffect(() => {
        const fetchSpecialShifts = async () => {
            try {
                const response = await fetch(`${url}/special-shifts-all/${adminId}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `bearer ${localStorage.getItem('authToken')}` 
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch special shifts');
                }

                const data = await response.json();
                if (data.status) {
                    setShifts(data.data); 
                    console.log("Fetched special shifts:", data.data); 
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.error('Error fetching special shifts:', error);
                toast.error('Failed to fetch special shifts.');
            }finally{
                setLoad(false)
            }
        };

        fetchSpecialShifts();
    }, []); 

    return (
        <div className="flex w-full h-screen">
            {/* <SideMenu /> */}
            <div className="w-full overflow-scroll scrollbar-hide px-2">
                <div className="flex justify-between sticky top-0 max-sm:top-7   bg-white lg:p-5  md:p-5 max-sm:p-4 max-sm:mt-10 sm:mt-10  md:mt-5 max-sm:-ml-3">
                    <div>
                        <h1 className="text-[22px] font-semibold">Special Shift</h1>
                        <p>This Is Sub Description</p>
                    </div>
                    <div onClick={handleProfileClick} className="flex space-x-2 mr-3 max-sm:hidden cursor-pointer">
                    <img
                            src={parsedProfileImage || "../4Shifter-icon.png"}
                            className="h-[40px] w-[40px] rounded-full"
                            alt={"admin profile"}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../4Shifter-icon.png";
                            }}
                        />
                        <div className="text-[14px]">
                            <h1 className="text-blue-700 font-semibold">{contactPerson} </h1>
                            <p>{email || "not found"}</p>
                        </div>
                    </div>
                </div>

                <div className="lg:p-5 lg:mt-5 max-sm:p-4 md:mb-5 sm:mb-5   max-sm:mt-5 pb-0 flex justify-between sticky  max-sm:top-24  sm:top-24 bg-white">
                    <h2 className="lg:text-[22px]  font-semibold max-sm:hidden ">In-Progress  Special Shifts</h2>
                    <div  style={{ fontFamily: 'Inder' }} className="bg-blue-700 cursor-pointer text-white px-6 py-2 rounded-xl max-sm:text-sm max-sm:-ml-2 "
                    onClick={()=>{
                        setSpecialId("")
                        navigate(`/create-special-shift/${"create"}`)
                    }}
                    >
                        + Create Special Shift
                    </div>
                </div>

                {/* Table Section */}
                <div className="mt-0 lg:-mt-5 ">
                    <div className="overflow-x-auto scrollbar-hide">
                        {load ?(
                             <div className="flex justify-center text-center  mt-10 font-bold text-lg">
                             <HourglassEmptyIcon className="animate-spin " /> &nbsp; Loading Special Shifts...
                            </div>
                        ):shifts.length === 0 ? (
                            <p className="text-center mt-10 font-semibold italic">No SpecialShift Created Yet </p>
                         ):(
                            <table className="w-full  bg-white "    >
                            <thead className="bg-gray-100 ">
                                <tr>
                                {/* <th className="py-3 px-6 max-sm:px-14">S.NO</th> */}
                                    {/* <th className="py-3 px-6 max-sm:px-14">Shift ID</th> */}
                                    <th className="py-3 px-6 max-sm:px-28 text-start">Title</th>
                                    <th className="py-3 px-6 max-sm:px-14">Dates</th>
                                    <th className="py-3 px-6 max-sm:px-14">Timings</th>
                                    <th className="py-3 px-6 max-sm:px-14">Rewards</th>
                                    <th className="py-3 px-6 max-sm:px-14">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="" style={{overflow:"scroll" ,height:"100px"}}>
                                {shifts.map((shift,key) => (
                                    
                                    <tr key={shift._id} className="border-b text-center">
                                        {/* <td className="py-3 px-6">{key+1}</td>  */}
                                        {/* <td className="py-3 px-3 max-sm:px-0">{shift._id}</td>  */}
                                        <td className="py-3 px-3 max-sm:px-0 text-start font-medium">{shift.shiftTitle}</td> 
                                        <td className="py-3 px-3 max-sm:px-0">
    {shift.startDate} {shift.endDate ? `TO ${shift.endDate}` : ""}
</td>
                                        <td className="py-3 px-3 max-sm:px-0">{`${shift.start} - ${shift.end}`}</td> 
                                        <td className="py-3 px-3 max-sm:px-0">{shift.rewardsCoins || "-"}</td>
                                        <td className="py-3 px-3 max-sm:px-0 flex justify-center gap-2">
                                            <button  style={{ fontFamily: 'Inder' }} className="bg-blue-700 text-white px-6 py-1 rounded-xl" onClick={() => handleEdit(shift._id)}>
                                                Edit
                                            </button>
                                            <button  style={{ fontFamily: 'Inder' }} className="bg-[#FFA500] text-white px-4 py-1 rounded-xl" onClick={() => handleView(shift)}>
                                                View Details
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                         )
                        }
                       
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
}

export default SpecialShift;
