import React, { useState, useEffect } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import HttpsIcon from '@mui/icons-material/Https';
import { useNavigate } from 'react-router-dom';
import { url } from '../../urlConfig';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [fcmToken, setFcmToken] = useState('');
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);  
  const navigate = useNavigate();

  // Check if the user is already logged in (based on localStorage)
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      // If the authToken exists in localStorage, navigate to the employee page directly
      navigate('/employee');
    }
  }, [navigate]);

  const handleLogin = async () => {
    setError('');
    try {
      if (!email || !password) {
        setError('Please enter email and password');
        return;
      }

      console.log("Email:", email, "Password:", password, "FCM Token:", fcmToken);

      const response = await fetch(`${url}/login-admin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          fcmToken,
        }),
      });

      console.log('Response:', response);

      if (!response.ok) {
        const errorData = await response.json();
        setError(`Error: ${response.status} ${errorData.message}`);
        console.error('Response Status:', response.status, errorData.message);
        return;
      }else{
        const data = await response.json();
        console.log('Login Response Data:', data);
  
        if (data.status === true) {
          if(keepLoggedIn){
            const storageMethod = keepLoggedIn ? localStorage : sessionStorage;
            // Store details based on 'Keep me logged in' status
            storageMethod.setItem('authToken', data.token);
            storageMethod.setItem('theAdminId', data.data._id);
            storageMethod.setItem('loginStatus', true);
            storageMethod.setItem("org", data.data.organizationName);
            storageMethod.setItem('contactPerson', data.data.contactPersonName);
            storageMethod.setItem('profile', JSON.stringify(data.data.profileImage));
            storageMethod.setItem('email', data.data.email);
            navigate('/employee');
          }else{
            localStorage.setItem('authToken', data.token);
            localStorage.setItem('theAdminId', data.data._id);
            localStorage.setItem('loginStatus', true);
            localStorage.setItem("org", data.data.organizationName);
            localStorage.setItem('contactPerson', data.data.contactPersonName);
            localStorage.setItem('profile', JSON.stringify(data.data.profileImage));
            localStorage.setItem('email', data.data.email);
            navigate('/employee');
          }
          
          
         
        } else {
          setError(data.message || 'Something went wrong');
        }
      }

      
    } catch (error) {
      console.error('Error during login:', error);
      setError('Please try again.');
    }
  };

  return (
    <div className="lg:flex sm:flex max-sm:flex-none">
      <img
        src="./login.png"
        alt="Login Illustration"
        className="lg:w-[40%] h-[100vh] max-sm:w-[100%] max-sm:h-[30vh] lg:p-7 max-sm:p-3"
      />
      <div className="lg:p-10 max-sm:p-4 sm:p-10 items-center lg:pt-28 lg:ml-20">
        <img src="./Watch.png" alt="Logo" className="w-20 h-20 max-sm:hidden" />
        <h1 className="text-[32px] font-bold lg:mt-7 max-sm:-mt-5">Welcome Back!</h1>
        <p className="pb-5 max-sm:-mt-3">Login to continue</p>

        {/* Email Input */}
        <label className="font-medium max-sm:text-[20px]">Email</label>
        <div className="flex border-2 border-[#306284] py-2 px-4 lg:w-[370px] rounded-xl shadow-sm lg:mt-2 mb-5">
          <EmailIcon className="text-[#89C8D1] max-sm:mt-2" />
          <input
            type="email"
            placeholder='Email'
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="text-[#344054] opacity-60 focus:outline-none max-sm:text-[25px] w-full ml-4"
          />
        </div>

        {/* Password Input */}
        <label className="font-medium max-sm:text-[20px]">Password</label>
        <div className="flex border-2 border-[#306284] py-2 px-4 lg:w-[370px] rounded-xl shadow-sm lg:mt-2 mb-5">
          <HttpsIcon className="text-[#89C8D1] max-sm:mt-2" />
          <input
            type="password"
            placeholder='Password'
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="text-[#344054] opacity-60 focus:outline-none max-sm:text-[25px] w-full ml-4"
          />
        </div>

        {/* Keep Me Logged In */}
        <div className="flex -mt-3 mb-7">
          <label className="relative flex items-center cursor-pointer">
            <input 
              type="checkbox" 
              className="sr-only peer" 
              checked={keepLoggedIn}
              onChange={(e) => setKeepLoggedIn(e.target.checked)} // Handle checkbox change
            />
            <span className="h-4 w-4 rounded-full border border-gray-400 peer-checked:bg-[#89C8D1]"></span>
          </label>
          <p className="ml-2 max-sm:text-[20px]">Keep me logged in</p>
                 {/* Error Message */}
        <a href='/new-password' className='hover:underline hover:text-[#89C8D1] ml-16'>Forgot Password ?</a>
        

        </div>
        {error && <p className="text-red-500 mb-4 -mt-5">{error}</p>}

 

        {/* Login Button */}
        <button
          onClick={handleLogin}
          className="text-[18px] font-medium text-white bg-[#89C8D1] text-center lg:px-40 sm:px-40 max-sm:px-44 lg:py-2 sm:py-2 max-sm:py-3 max-sm:ml-1 rounded-xl hover:border border-gray-400"
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default Login;
