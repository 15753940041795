// import Head from 'next/head';
// import Link from 'next/link';
import { Link, useNavigate, useParams } from "react-router-dom";
export default function Testb() {
  return (
    <>
      {/* SEO Meta Tags */}
      {/* <Head>
        <title>BrainBox Apps | Exceptional Mobile Experience</title>
        <meta
          name="description"
          content="Your trusted partner in crafting innovative mobile solutions that drive growth and deliver exceptional user experiences. Let's bring your app idea to life."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="BrainBox Apps | Exceptional Mobile Experience" />
        <meta
          property="og:description"
          content="Transform your vision into an exceptional mobile experience with BrainBox Apps."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.brainboxapps.com" />
      </Head> */}

      {/* Navbar */}
      
      <header className="flex justify-between items-center px-6 py-4 shadow-sm bg-white">
        <div className="text-xl font-bold text-gray-800">BrainBox Apps</div>
        <nav className="flex gap-6 text-gray-600">
          <Link href="#services">Services</Link>
          <Link href="#process">Process</Link>
          <Link href="#work">Work</Link>
          <Link href="#about">About</Link>
          <Link href="#start">
            <button className="bg-blue-600 text-white px-4 py-2 rounded-md">Start Your Project</button>
          </Link>
        </nav>
      </header>

      {/* Hero Section */}
      <main className="text-center py-20 px-6 bg-gray-50">
        <h1 className="text-4xl md:text-6xl font-extrabold text-gray-800 mb-4">
          Transform Your Vision into{' '}
          <span className="text-blue-600">Exceptional Mobile Experience</span>
        </h1>
        <p className="text-lg md:text-xl text-gray-600 max-w-3xl mx-auto mb-8">
          Your trusted partner in crafting innovative mobile solutions that drive growth and deliver exceptional user
          experiences. Let&apos;s bring your app idea to life.
        </p>
        <div className="flex justify-center gap-4">
          <button className="bg-blue-600 text-white px-6 py-3 rounded-md font-medium hover:bg-blue-700">
            Schedule a Free Consultation
          </button>
          <button className="border border-blue-600 text-blue-600 px-6 py-3 rounded-md font-medium hover:bg-blue-50">
            Explore Our Services
          </button>
        </div>
      </main>

      {/* Statistics Section */}
      <section className="grid grid-cols-2 md:grid-cols-4 gap-6 px-6 py-12 bg-white">
        <div className="text-center">
          <h3 className="text-3xl font-bold text-gray-800">100+</h3>
          <p className="text-gray-600">Apps Delivered</p>
        </div>
        <div className="text-center">
          <h3 className="text-3xl font-bold text-gray-800">98%</h3>
          <p className="text-gray-600">Client Satisfaction</p>
        </div>
        <div className="text-center">
          <h3 className="text-3xl font-bold text-gray-800">50+</h3>
          <p className="text-gray-600">Expert Developers</p>
        </div>
        <div className="text-center">
          <h3 className="text-3xl font-bold text-gray-800">8+</h3>
          <p className="text-gray-600">Years Experience</p>
        </div>
      </section>
    </>
  );
}
