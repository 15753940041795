import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export default function CustomDatePickerReport(props) {
  // Ensure the date is correctly initialized as dayjs object
  const initialDate = dayjs(props.date, "DD/MM/YYYY");
  const today = dayjs();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
          label={props.label}
        value={initialDate.isValid() ? initialDate : null}  // Ensure a valid date is passed
        onChange={(newDate) => {
          if (newDate) {
            // Update the date in the parent component when a date is selected
            props.setDate(newDate.format("DD/MM/YYYY"));
            console.log(newDate.format("DD/MM/YYYY"));
          }
        }}
        format="DD/MM/YYYY"
        maxDate={today}
        sx={{
          borderRadius: '12px',
          '& .MuiOutlinedInput-root': { borderRadius: '12px' }
        }}
      />
    </LocalizationProvider>
  );
}
