import React from "react";
import { useState } from "react";
import { BrowserRouter as Router, Route, Routes ,useLocation } from "react-router-dom"
import './App.css';
import Employees from "./Pages/Employees";
import ManageProfile from "./Pages/Profile";
import Chats from "./Pages/Chats";
import AllShift from "./Pages/Shifts";
import Shedule from "./Pages/Shedules";
import SpecialShift from "./Pages/SpecialShift";
import AllRewards from "./Pages/Rewards";
import Leaves from "./Pages/Leave";
import AllReports from "./Pages/Reports";
import Subscription from "./Pages/Subscription";
import PaymentInfo from "./Pages/PaymentPage";
import Login from "./Pages/Login";
import CreateSpecialShift from "./Pages/SpecialShift/createSpecialShift";
import ViewSpecialShift from "./Pages/SpecialShift/viewSpecialShift";
import  { LoginContext }  from './LoginContext'
import Protected from "./Protected";
import NewPassword from "./Pages/Login/forgotPassword";
import Testb from "./Pages/Testb";
import SideMenu from "./Components/Sidebar/sidebar";

// import EditSpecialShift from "./Pages/SpecialShift/EditSpecialShift";

function App() {
  const [singleUser,setSingleUser]=useState({})
  

  return (
    <>
    <LoginContext.Provider value={{singleUser,setSingleUser}}>
   
    <Router>
    <MainLayout>
      <Routes>
      <Route path='/' element={ <Login/>}></Route>
      <Route path='/testb' element={ <Testb/>}></Route>
     <Route path="/new-password" element={<NewPassword/>}></Route>
      <Route path='/chats' element={<Protected Component={Chats}/>}></Route>
      <Route path='/employee' element={<Protected Component={Employees}/>}></Route>
      <Route path='/shifts' element={<Protected Component={AllShift}/>}></Route>
      <Route path='/create-special-shift/:specialId' element={<Protected Component={CreateSpecialShift}/>}></Route>
      <Route path='/special-shift-detail' element={<Protected Component={ViewSpecialShift}/>}></Route>
      <Route path='/schedule' element={<Protected Component={Shedule}/>}></Route>
      <Route path='/specialShift' element={<Protected Component={SpecialShift}/>}></Route>
      {/* <Route path="/edit-special-shift/:shiftId" element={<Protected Component={EditSpecialShift}/>}></Route> */}
      <Route path='/rewards' element={<Protected Component={AllRewards}/>}></Route>
      <Route path='/leave' element={<Protected Component={Leaves}/>}></Route>
      <Route path='/profile' element={<Protected Component={ManageProfile}/>}></Route>
      <Route path='/reports' element={<Protected Component={AllReports}/>}></Route>
      <Route path='/profile/Subscription' element={<Protected Component={Subscription}/>}></Route>
      <Route path='/profile/Subscription/payment' element={<Protected Component={PaymentInfo}/>}></Route>
       
      
      </Routes>
      </MainLayout>
      </Router>
     
      </LoginContext.Provider>

    </>
  );
}

function MainLayout({ children }) {
  const location = useLocation();

  const shouldShowSidebar = () => {
    const pathsWithoutSidebar = ['/', '/new-password', '/testb'];
    return !pathsWithoutSidebar.includes(location.pathname);
  };

  return (
    <div className="app-container flex h-[100vh]">
      {/* Render the SideMenu only for routes that require it */}
      {shouldShowSidebar() && <SideMenu />}
      
      {/* Main Content Area */}
      <div className={`main-content ${shouldShowSidebar() ? "lg:w-[80%] max-sm:w-[100%]" : "w-full"}`}>
        {children}
      </div>
    </div>
  );
}
export default App;
