import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SideMenu from "../../Components/Sidebar/sidebar";
import CustomDatePicker from "../../Components/TimePicker/datePicker";
import TimePicker from "../../Components/TimePicker";
import dayjs from 'dayjs';
import { Link, useNavigate, useParams } from "react-router-dom";
import { url } from "../../urlConfig";
import io from 'socket.io-client';
import CircularProgress from '@mui/material/CircularProgress';

const socket = io("ws://159.65.153.38:80");


function CreateSpecialShift() {

    useEffect(() => {
        // Establish connection when the component mounts
       
    
        // // Cleanup on unmount
        // return () => {
        //   socket.disconnect();
        // };
      }, []);
    

    const [shifts, setShifts] = useState([]);
    const [shiftTitle, setShiftTitle] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [hasEndDate, setHasEndDate] = useState(false);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [groupId, setGroupId] = useState('');
    const [rewardsCoins, setRewardsCoins] = useState("");
    const [image, setImage] = useState("");
    const [maxEmployees, setMaxEmployees] = useState("");
    const [groups, setGroups] = useState([]);
    const { specialId } = useParams();
    const [isUploading, setIsUploading] = useState(false);
    console.log(specialId)
    const handleCheckboxChange = () => {
        setHasEndDate(!hasEndDate);
        if (hasEndDate) {
            setEndDate(null);
        }
    };

    const navigate = useNavigate();

    const adminId = localStorage.getItem('theAdminId');



    useEffect(() => {

        

        const fetchGroups = async () => {


            try {
                const response = await fetch(`${url}/get-all-groups/${adminId}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `bearer ${localStorage.getItem('authToken')}`
                    }
                });

                console.log("API Response status:", response.status);
                const data = await response.json();
                console.log("API Response data:", data);

                if (data.status) {
                    setGroups(data.data);
                    console.log("Groups fetched:", data.data);
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
                toast.error('Failed to fetch groups.');
            }
        };

        fetchGroups();
    }, [groupId]);

    useEffect(() => {
        if (specialId !== "create") {
            fetch(`${url}/single-special-shift/${specialId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `bearer ${localStorage.getItem('authToken')}`
                }
            }).then((res) => { return res.json() })
                .then(response => {
                    if (response.status === true) {
                        setShiftTitle(response.data.shiftTitle)
                        setStartDate(response.data.startDate)
                        setEndDate(response.data.endDate)
                        setStart(dayjs(response.data.start, 'HH:mm'))
                        setEnd(dayjs(response.data.end, 'HH:mm'))
                        setGroupId(response.data.groupId)
                        setRewardsCoins(response.data.rewardsCoins)
                        setImage(response.data.image)
                        setMaxEmployees(response?.data?.maxEmployees)
                    }

                })
        }

    }, [])
    const handleFileChange = (e) => {
        const formData = new FormData();

        formData.append('newImage', e.target.files[0])
        console.log(e.target.file)
        // console.log(fileList)
        setIsUploading(true);
        fetch(`${url}/upload-admin-profile-image/specialShiftImage`, {
            method: "POST",
            //   headers :{"Content-Type":"application/json" },
            body: formData
        }).then((res) => { return res.json() })
            .then(response => {
                setImage(response.imageUrl)
                console.log(response.imageUrl)
                setIsUploading(false);

            })

    };

    const validateForm = () => {
        toast.dismiss();

        if (!shiftTitle) {
            toast.error("Shift Title is required");
            return false;
        }
        if (!startDate) {
            toast.error("Date is Required")
            return false;
        }
        if (!start) {
            toast.error("Start Time is required");
            return false;
        }
        if (!end) {
            toast.error("End Time is required");
            return false;
        }
        // if (start >= end) {
        //     toast.error("End Time must be after Start Time");
        //     return false;
        // }
        if (!groupId) {
            toast.error("Please select a group to send the request");
            return false;
        }
        if (maxEmployees <= 0) {
            toast.error("Maximum number of employees must be greater than 0");
            return false;
        }
        if (rewardsCoins < 0) {
            toast.error("Reward Coins cannot be negative");
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!validateForm()) {
            return;
        }
    
        const adminId = localStorage.getItem('theAdminId');
    
        try {
            const formData = {
                specialId,
                adminId,
                shiftTitle,
                startDate: startDate,
                endDate: endDate,
                start: dayjs(start, 'HH:mm').format('HH:mm'),
                end: dayjs(end, 'HH:mm').format('HH:mm'),
                groupId,
                maxEmployees: maxEmployees,
                rewardsCoins,
                image: image
            };
    
            // For creating a new special shift, use socket.emit directly
            if (specialId === "create") {
                socket.on('connection', () => {
                    console.log('Socket connected:'); // You can use this ID if needed
                  });
                // Emit the 'createSpecialShift' event to the server via socket
                socket.emit('createSpecialShift', formData);
    
                // Success message and UI update
                toast.success('Notification sent to all employees!');
                console.log('Shift created and notification sent.');
    
                navigate('/specialShift');
                setShiftTitle("");
                setStartDate(null);
                setEndDate(null);
                setStart(null);
                setEnd(null);
                setGroupId("");
                setImage("");
                setRewardsCoins("");
                setMaxEmployees("");
            } else {
                // Keep the 'edit-specialShift' API call for updating an existing shift
                fetch(`${url}/edit-specialShift`, {
                    method: 'PUT',
                    body: JSON.stringify(formData),
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `bearer ${localStorage.getItem('authToken')}`
                    }
                }).then((res) => { return res.json() })
                    .then(response => {
                        if (response.status === true) {
                            toast.success(response.message);
                            navigate("/specialShift");
                        } else {
                            toast.error(response.message);
                        }
                    });
            }
    
        } catch (error) {
            toast.error('Failed to create special shift.');
        }
    };
    
    return (
        <>

            <ToastContainer />
            <div className="flex w-full h-screen">
                {/* <SideMenu /> */}
                <div className=" overflow-scroll scrollbar-hide px-3">
                    <div className="flex justify-between lg:p-5 lg:mt-5 max-sm:p-4 max-sm:mt-10 max-sm:-ml-4 sm:p-3 sticky top-0 bg-white z-10">
                        <div>
                            {specialId === "create" ? <h1 className="text-[22px] font-semibold">Create Special Shift</h1> : <h1 className="text-[22px] font-semibold">Edit Special Shift</h1>}
                            <p>This is sub description</p>
                        </div>
                        <div className="flex space-x-2 mr-3 max-sm:hidden sm:hidden">
                            <img src="https://img.freepik.com/premium-photo/stylish-man-flat-vector-profile-picture-ai-generated_606187-310.jpg" className="h-[40px] w-[40px] rounded-full" />
                            <div className="text-[14px]">
                                <h1 className="text-blue-700 font-semibold">brainbox user</h1>
                                <p>testuser@gmail.com<ExpandMoreIcon /></p>
                            </div>
                        </div>
                    </div>

                    {/* Create Special Shift */}
                    <div className="max-w-4xl lg:p-5 max-sm:p-3 max-sm:-ml-3 max-sm:mt-5 bg-white lg:space-y-6 max-sm:space-y-3 sm:space-y-4 sm:mt-10 sm:pr-4">
                        <TextField
                            fullWidth
                            label="Shift Title*"
                            className="mb-4"
                            value={shiftTitle}
                            onChange={(e) => setShiftTitle(e.target.value)}
                            sx={{ borderRadius: '12px', '& .MuiOutlinedInput-root': { borderRadius: '12px' } }}
                        />
                        <div>
                            <div className="flex items-center lg:gap-4 mb-2   ">
                                <CustomDatePicker date={startDate} setDate={setStartDate} value={startDate} />
                                {hasEndDate && (
                                    <CustomDatePicker date={endDate} setDate={setEndDate} value={endDate} className="ml-4" />
                                )}
                            </div>
                            <div className="text-[15px] max-sm:text-[13px] flex items-center">
                                <input
                                    type="checkbox"
                                    className="mr-2"
                                    checked={hasEndDate}
                                    onChange={handleCheckboxChange}
                                />
                                This shift has an ending date
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <FormControl className="mb-4">
                                <label>Shift Start Time</label>
                                <TimePicker value={start} onChange={(newStart) => { setStart(newStart); console.log(newStart) }} />
                            </FormControl>

                            <FormControl className="mb-4">
                                <label>Shift End Time</label>
                                <TimePicker value={end} onChange={(newEnd) => setEnd(newEnd)} />
                            </FormControl>
                        </div>

                        {/* Select Group */}
                        <FormControl fullWidth className="mb-4">
                            <InputLabel>Send Request To Group</InputLabel>
                            <Select
                                value={groupId}
                                onChange={(e) => {
                                    console.log("Selected Group ID:", e.target.value);
                                    setGroupId(e.target.value);
                                }}
                                label="Send request to Group :"
                            >
                                {groups.map((group) => (
                                    <MenuItem key={group._id} value={group._id}>
                                        {group.groupName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <p className="text-[15px] max-sm:text-[13px] ">Note: The selected employees will receive a notification to either accept or reject. You can view the details under “Shift Details”.</p>
                        <TextField
                            fullWidth
                            type=""
                            value={maxEmployees}
                            onChange={(e) => setMaxEmployees(e.target.value)}
                            label="Maximum number of employees to be assigned this shift"
                            className="mb-4 w-full"
                        />
                        <TextField
                            fullWidth
                            type=""
                            label="Reward Coins"
                            value={rewardsCoins}
                            onChange={(e) => setRewardsCoins(e.target.value)}
                            className="mb-4 w-full"
                        />
                        {/* <div className="text-[15px] max-sm:text-[13px] flex">
                            <input type="checkbox" className="mr-2" />
                            This shift has no reward coins offer
                        </div> */}
                        <p className="text-[15px] max-sm:text-[13px]">Note: Reward coins motivate the employee to join this shift. After successful completion of the shift, the coins will be sent to the employee.</p>

                        <div className="w-36 h-24 border border-dashed border-gray-300 rounded-lg flex items-center justify-center relative cursor-pointer">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                className="absolute inset-0 opacity-0 cursor-pointer"
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="0.5"
                                stroke="currentColor"
                                className="w-8 h-8"
                            >
                                <FileUploadIcon />
                            </svg>
                            <br />
                            <p className="font-medium text-gray-500">Upload Image</p>
                        </div>

                        {isUploading && (
                            <div className="mt-4 text-start">
                                <CircularProgress size={24} />
                                <p className="font-medium text-red-500">Uploading image, please wait...</p>
                            </div>
                        )}

                        {image && !isUploading && (
                            <div className="mt-4">
                                <img
                                    src={image}
                                    alt="Selected"
                                    className="w-24 -mt-3 h-16 object-cover border border-gray-300 rounded-lg"
                                />
                            </div>
                        )}

                        <button onClick={handleSubmit} type="submit" className="font-medium text-center bg-blue-600 rounded-lg px-40 max-sm:px-28 py-2 text-white">Save</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateSpecialShift;
